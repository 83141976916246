import React, { useState, useRef, useEffect, useContext } from 'react';
import { DEFAULT_COLOUR_PARAMETERS, DEFAULT_CONTRAST, DEFAULT_EXPOSURE, DEFAULT_SATURATION, DEFAULT_ZOOM } from './matchUtils';
import { CanvasDisplay } from './CanvasDisplay';
import DragAndDrop from './DragAndDrop';
import FloatingBottomPanel from './FloatingBottomPanel';
import ImageLoader from './ImageLoader';
import ColourMatching from './matchers/ColourMatching';

import { COLOUR_875, COLOUR_1000, COLOUR_950, compareParams } from './utils';
import { ColorWheelFxControlComponent } from './wheels/ColorWheelFxControlComponent';
import LightingSaturation from './sliders/LightingSaturation';
import FloatingTopPanel from './FloatingTopPanel';

import { Helmet } from "react-helmet";
import Display from './Display';
import { AppContext } from '../AppContext';
import DownloadModal from './DownloadModal';

export const DesktopImageEditor = ({
  sourceImage,
  targetImageThumbnail,
  imgData,
  params,
  history,
  undo,
  redo,
  isDisabled,
  isMatchingColours,
  handleSourceImageUpload,
  handleContrastChange,
  handleExposureChange,
  handleSaturationChange,
  handleParameterChange,
  handleOffsetChange,
  handleColourChange,
  updateHistory,
  onSourceImageChange,
  onTargetChange,
  onRemoveImage,
  onDeleteUserImage,
  onUserImageLoading,
  onUserImageLoaded
}) => {
  const topLevelRef = useRef(null);
  const { error, isShowDownload } = useContext(AppContext);
  return (
    <main
      ref={topLevelRef}
      style={{
        display: "flex", // Flexbox for horizontal layout
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically (if you want to center within the parent)
        width: "100vw", // Ensure it takes up full available width
        height: "100%",
        background: COLOUR_1000,
        overflow: 'hidden',
        position: 'relative'
      }}
    >      
      <div 
        style={{
          display: "flex", // Use flexbox for horizontal alignment
          background: COLOUR_950,
          width: '100%',
          height: '100%'
        }}
      >
        {/* Display */}
        <Display
          imgData={imgData}
          sourceImage={sourceImage}
          params={params}
          history={history}
          undo={undo}
          redo={redo}
          isDisabled={isDisabled}
          handleSourceImageUpload={handleSourceImageUpload}
          onSourceImageChange={onSourceImageChange}
          onRemoveImage={onRemoveImage}
        />

        {/* Control Panel */}
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Stack sliders vertically
            width: '30%',
            height: '100%',
            background: COLOUR_875,
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <ColourMatching
            params={params}
            targetImageThumbnail={targetImageThumbnail}
            onTargetChange={onTargetChange}
            onDeleteUserImage={onDeleteUserImage}
            onUserImageLoading={onUserImageLoading}
            onUserImageLoaded={onUserImageLoaded}
            isDisabled={isDisabled}
            isMatchingColours={isMatchingColours}
          />
          <LightingSaturation
            isDisabled={isDisabled}
            params={params}
            updateHistory={updateHistory}
            handleContrastChange={handleContrastChange}
            handleExposureChange={handleExposureChange}
            handleSaturationChange={handleSaturationChange}
          />
          <ColorWheelFxControlComponent
            isDisabled={isDisabled}
            updateHistory={updateHistory}
            params={params}
            onParameterChange={handleParameterChange}
            onOffsetChange={handleOffsetChange}
            onColourChange={handleColourChange}
          />
        </div>
      </div>
      {
        isShowDownload
        &&
        <DownloadModal isMobile={false} isDisabled={isDisabled}/>
      }
    </main>
  );
};

export default DesktopImageEditor;
