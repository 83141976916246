import React, { useEffect, useState } from 'react';
import HueSlider from './HueSlider';
import { Color } from '../../utils';
import SaturationSlider from './SaturationSlider';
import OffsetSlider from './OffsetSlider';

export const ColourAdjustments = ({
  params,
  handleColourChange,
  handleOffsetChange,
  updateHistory,
  isDisabled,
  type="shadow"
}) => {
  let colour, defaultOffset;
  if (type == 'shadow') {
    colour = params.shadowParameters.colour;
    defaultOffset = params.shadowParameters.offset;
  } else if (type == 'midtone') {
    colour = params.midtoneParameters.colour;
    defaultOffset = params.midtoneParameters.offset;
  } else if (type == 'highlight') {
    colour = params.highlightParameters.colour;
    defaultOffset = params.highlightParameters.offset;
  }

  const { h: defaultHue, s: defaultSaturation } = new Color(colour.red, colour.green, colour.blue).toHsv();
  const [hue, setHue] = useState(defaultHue);
  const [saturation, setSaturation] = useState(defaultSaturation);
  const [offset, setOffset] = useState(defaultOffset);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20
      }}
    >
      <SaturationSlider
        value={saturation}
        prefix={type}
        onChange={(newSaturation) => {
          setSaturation(newSaturation);
          handleColourChange(type, Color.fromHsv(hue, newSaturation, 0.5));
        }}
        updateHistory={updateHistory}
        isDisabled={isDisabled}
        hue={hue}
      />
      <HueSlider
        value={hue}
        prefix={type}
        onChange={(newHue) => {
          setHue(newHue);
          handleColourChange(type, Color.fromHsv(newHue, saturation, 0.5));
        }}
        updateHistory={updateHistory}
        isDisabled={isDisabled}
      />
      <OffsetSlider
        value={offset}
        prefix={type}
        onChange={(newOffset) => {
          setOffset(newOffset);
          handleOffsetChange(type, newOffset);
        }}
        updateHistory={updateHistory}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default ColourAdjustments;
