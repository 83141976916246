import { bypass,
    blendNormal,
    isPositionOutside,
  } from './glsl';
    
  import { EffectsManager, EffectsMode } from './EffectsManager';
  
  class ShaderProgram {
    constructor(gl) {
      this.gl = gl;
      this.updateProgram();
    }
  
    resetAttributes() {
      this.attributes = {};
    }
  
    resetUniforms() {
      this.uniforms = {};
    }
  
    registerAttribute(name) {
      this.attributes[name] = this.gl.getAttribLocation(this.program, name);
    }
  
    registerUniform(name) {
      this.uniforms[name] = this.gl.getUniformLocation(this.program, name);
    }
  
    updateProgram() {
      const gl = this.gl;
      this.vertexShader = this.#compileShader(gl, this.getVertexShaderScript(), gl.VERTEX_SHADER);
      this.fragmentShader = this.#compileShader(gl, this.getFragmentShaderScript(), gl.FRAGMENT_SHADER);
      this.program = this.#createProgram(gl, this.vertexShader, this.fragmentShader);
      gl.useProgram(this.program);
  
      this.resetAttributes();
      this.resetUniforms();
  
      // register attributes and uniforms
      this.registerAttribute('a_position');
      this.registerAttribute('a_texCoord');
  
      this.registerUniform('u_image0');
      this.registerUniform('u_image1');
      this.registerUniform('u_blendTwoTextures');
      this.registerUniform('u_params[0]');
      this.registerUniform('u_mode');
      this.registerUniform('u_textureSize');
      this.registerUniform('u_resolution');
      this.registerUniform('u_flipY');
      this.registerUniform('u_matrix');
      this.registerUniform('u_scale');

      gl.enable(gl.SCISSOR_TEST);
    }
  
    #compileShader(gl, shaderSource, shaderType) {
      var shader = gl.createShader(shaderType);
      gl.shaderSource(shader, shaderSource);
      gl.compileShader(shader);
      var success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
      if (!success) {
        gl.deleteShader(shader);
        throw new Error("could not compile shader:" + gl.getShaderInfoLog(shader));
      }
     
      return shader;
    }
  
    #createProgram(gl, vertexShader, fragmentShader) {
      var program = gl.createProgram();
      gl.attachShader(program, vertexShader);
      gl.attachShader(program, fragmentShader);
      gl.linkProgram(program);
      var success = gl.getProgramParameter(program, gl.LINK_STATUS);
      if (!success) {
        gl.deleteProgram(program);
          throw new Error("program failed to link:" + gl.getProgramInfoLog (program));
      }
      return program;
    };
  
    getVertexShaderScript() {
      return `
        attribute vec2 a_position;
        attribute vec2 a_texCoord;
  
        uniform vec2 u_resolution;
        uniform float u_flipY;
        uniform mat4 u_matrix;
        uniform float u_scale;
  
        varying vec2 v_texCoord;
  
        void main() {
           vec4 transformedPosition = u_matrix * vec4(a_position * u_scale, 0, 1);

           // converting to clip space -1 -> +1
           vec2 zeroToOne = transformedPosition.xy / u_resolution;
           vec2 zeroToTwo = zeroToOne * 2.0;
           vec2 clipSpace = zeroToTwo - 1.0;
           gl_Position = vec4(clipSpace * vec2(1, u_flipY), 0, 1);
           v_texCoord = a_texCoord;
        }
      `;
    }
  
    getFragmentShaderScript() {
      return `
        #define MAX_PARAMS 20
        #define PI 3.14159265359
        precision mediump float;
  
        // our texture
        uniform sampler2D u_image0;
        uniform sampler2D u_image1;
        
        uniform int u_blendTwoTextures;
  
        uniform vec2 u_textureSize;
        uniform float u_params[MAX_PARAMS];
        uniform int u_mode;
  
        // the texCoords passed in from the vertex shader.
        varying vec2 v_texCoord;
  
        ${isPositionOutside()}
  
        ${bypass()}
        ${blendNormal()}
  
        void main() {
  
          // Background color set to transparent
          gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
  
          if (isPositionOutside(v_texCoord)) {
            discard;
          }
  
          if (u_mode == ${EffectsMode.BYPASS}) {
            vec4 image = bypass(u_image0, v_texCoord);
            gl_FragColor = blendNormal(image, gl_FragColor, 1.0);
          }

          if (u_mode == ${EffectsMode.CHAIN}) {
            vec4 image = bypass(u_image0, v_texCoord);

            // Exposure
            float exposure = u_params[0];
            image.rgb = clamp(image.rgb * pow(2.0, exposure), 0.0, 1.0);

            // Saturation
            vec3 lumaWeights = vec3(0.25, 0.5, 0.25);
            float saturation = u_params[1];
            float grey = dot(image.rgb, lumaWeights);
            image.rgb = grey + saturation * (image.rgb - grey);

            // Contrast
            float contrast_log_midpoint = 0.18;
            float contrast_eps = 1e-5;
            float contrast = u_params[2];
            image.rgb = contrast_log_midpoint + (log2(image.rgb + contrast_eps) - contrast_log_midpoint) * contrast;            
            image.rgb = clamp(pow(vec3(2.0, 2.0, 2.0), image.rgb) - contrast_eps, 0.0, 1.0);

            // Color
            vec3 shadowColor = clamp(vec3(u_params[3], u_params[4], u_params[5]), 0.0, 1.0);
            float shadowOffset = clamp(u_params[6], -1.0, 1.0);
            vec3 midtoneColor = clamp(vec3(u_params[7], u_params[8], u_params[9]), 0.0, 1.0);
            float midtoneOffset = clamp(u_params[10], -1.0, 1.0);
            vec3 highlightColor = clamp(vec3(u_params[11], u_params[12], u_params[13]), 0.0, 1.0);
            float highlightOffset = clamp(u_params[14], -1.0, 1.0);
  
            float averageLift = (shadowColor.r + shadowColor.g + shadowColor.b) / 3.0;
            vec3 liftColor = shadowColor - averageLift;
  
            float averageGamma = (midtoneColor.r + midtoneColor.g + midtoneColor.b) / 3.0;
            vec3 gammaColor = midtoneColor - averageGamma;
  
            float averageGain = (highlightColor.r + highlightColor.g + highlightColor.b) / 3.0;
            vec3 gainColor = highlightColor - averageGain;
  
            vec3 liftAdjust = 0.0 + liftColor + shadowOffset;
            vec3 gainAdjust = 1.0 + gainColor + highlightOffset;
            vec3 midGrey = 0.5 + gammaColor + midtoneOffset;
            vec3 H = gainAdjust;
            vec3 S = liftAdjust;
  
            vec3 gammaAdjust = vec3(
              log((0.5 - S.x) / (H.x - S.x)) / log(midGrey.x),
              log((0.5 - S.y) / (H.y - S.y)) / log(midGrey.y),
              log((0.5 - S.z) / (H.z - S.z)) / log(midGrey.z)
            );
  
            gammaAdjust = clamp(gammaAdjust, 0.1, 2.0);

            image.rgb = clamp(liftAdjust + pow(image.rgb, 1.0 / gammaAdjust) * (gainAdjust - liftAdjust), 0.0, 1.0);

            gl_FragColor = image;
          }

        }
      `;
    }
  }
  
  export class ShaderFrame {
    constructor(gl) {
      this.gl = gl;
      this.program = new ShaderProgram(gl);
  
      this._prevTimeForDebugging = 0.0;
      this._perfTimeForDebugging = 0.0;
    }
  

    renderImage({srcs, srcX, srcY, srcWidth, srcHeight, dstX, dstY, dstWidth, dstHeight, zoom}) {
      const gl = this.gl;
      this.#updateView(srcX, srcY, srcWidth, srcHeight, dstX, dstY, dstWidth, dstHeight);
  
      gl.uniform1f(this.program.uniforms.u_flipY, -1);
      gl.uniform1i(this.program.uniforms.u_blendTwoTextures, 0);
      gl.uniform1i(this.program.uniforms.u_mode, EffectsMode.BYPASS);
      gl.uniform2f(this.program.uniforms.u_resolution, dstWidth, dstHeight);
      gl.uniform2f(this.program.uniforms.u_textureSize, srcWidth, srcHeight);
      const identityMatrix = [
        1, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1
      ];
      gl.uniformMatrix4fv(this.program.uniforms.u_matrix, false, identityMatrix);
      gl.uniform1f(this.program.uniforms.u_scale, zoom);

      const renderElementWithFxChains = (gl, element, fx, textureIndex) => {
        gl.uniform1i(this.program.uniforms[`u_image${textureIndex}`], textureIndex);
        gl.activeTexture(gl.TEXTURE0 + textureIndex);
  
        const texture = this.#createAndSetupTexture(gl);
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA,  gl.RGBA, gl.UNSIGNED_BYTE, element);
        gl.drawArrays(gl.TRIANGLES, 0, 6);
  
        if (fx.length == 0) {
          return texture;
        }
  
        const { webglFx } = new EffectsManager(fx);
  
        if (webglFx.length > 0) {
          const { textures, frameBuffers } = this.#createIntermediaryTexturesAndFrameBuffers(2, srcWidth, srcHeight);
          gl.uniform1i(this.program.uniforms[`u_image${textureIndex}`], textureIndex);
          gl.activeTexture(gl.TEXTURE0 + textureIndex);
          gl.bindTexture(gl.TEXTURE_2D, texture);
  
          for (let i = 0; i < webglFx.length; ++i) {
            const webglFxItem = webglFx[i];
  
            gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffers[i % frameBuffers.length]);
  
            gl.viewport(srcX, srcY, srcWidth, srcHeight);
            gl.scissor(srcX, srcY, srcWidth, srcHeight);
  
            gl.uniform1f(this.program.uniforms.u_flipY, 1);
  
            gl.uniform1i(this.program.uniforms.u_mode, webglFxItem.mode);
            webglFxItem.fn(gl, this.program);
  
            gl.drawArrays(gl.TRIANGLES, 0, 6);
  
            gl.uniform1i(this.program.uniforms[`u_image${textureIndex}`], textureIndex);
            gl.activeTexture(gl.TEXTURE0 + textureIndex);
            gl.bindTexture(gl.TEXTURE_2D, textures[i % textures.length]);        
          }
      
          gl.bindFramebuffer(gl.FRAMEBUFFER, null);
          gl.viewport(dstX, dstY, dstWidth, dstHeight);
          gl.scissor(dstX, dstY, dstWidth, dstHeight);
          gl.uniform1f(this.program.uniforms.u_flipY, -1);
          gl.uniform1i(this.program.uniforms.u_mode, EffectsMode.BYPASS);
          gl.drawArrays(gl.TRIANGLES, 0, 6);
  
          gl.deleteTexture(texture);
          gl.deleteTexture(textures[webglFx.length % textures.length]);
          gl.deleteFramebuffer(frameBuffers[webglFx.length % textures.length]);      
          return textures[(webglFx.length - 1) % textures.length]; 
        }
      };
  
      const t0 = performance.now()
      const textures = srcs.map(({element, fx}, textureIndex) => {
        const fxWithOrWithoutOpacity = (textureIndex == 0) ? fx : fx.slice(1);
        return renderElementWithFxChains(gl, element, fxWithOrWithoutOpacity, 0);
      });
      const t1 = performance.now()
  
      const blendTextures = (gl, bottomTexture, topTexture, webglFxItem) => {
        const { textures, frameBuffers } = this.#createIntermediaryTexturesAndFrameBuffers(1, srcWidth, srcHeight);
  
        gl.uniform1i(this.program.uniforms.u_image0, 0);
        gl.activeTexture(gl.TEXTURE0 + 0);
        gl.bindTexture(gl.TEXTURE_2D, bottomTexture);
  
        gl.uniform1i(this.program.uniforms.u_image1, 1);
        gl.activeTexture(gl.TEXTURE0 + 1);
        gl.bindTexture(gl.TEXTURE_2D, topTexture);
  
        gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffers[0]);
  
        gl.viewport(srcX, srcY, srcWidth, srcHeight);
        gl.scissor(srcX, srcY, srcWidth, srcHeight);
  
        gl.uniform1f(this.program.uniforms.u_flipY, 1);
        gl.uniform1i(this.program.uniforms.u_mode, webglFxItem.mode);
        gl.uniform1i(this.program.uniforms.u_blendTwoTextures, 1);
        webglFxItem.fn(gl, this.program);
  
        gl.drawArrays(gl.TRIANGLES, 0, 6);
  
        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, textures[0]);
        gl.activeTexture(gl.TEXTURE0 + 1);
        gl.bindTexture(gl.TEXTURE_2D, textures[0]);        
        
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.viewport(dstX, dstY, dstWidth, dstHeight);
        gl.scissor(dstX, dstY, dstWidth, dstHeight);
        gl.uniform1f(this.program.uniforms.u_flipY, -1);
        gl.uniform1i(this.program.uniforms.u_mode, EffectsMode.BYPASS);
        gl.drawArrays(gl.TRIANGLES, 0, 6);
  
        return textures[0];
      };
  
      if (textures.length > 1) {
        const t2 = performance.now();
        let texture = textures[0];
        for (let i = 1; i < textures.length; ++i) {
          const { webglFx } = new EffectsManager([srcs[i].fx[0]]);
          texture = blendTextures(gl, texture, textures[i], webglFx[0]);
        }
        const t3 = performance.now();
        return texture;  
      } else {
        return textures[0];
      }
    }
  
    #updateView(srcX, srcY, srcWidth, srcHeight, destX, destY, destWidth, destHeight) {
      const gl = this.gl;
      gl.viewport(destX, destY, destWidth, destHeight);
      gl.scissor(destX, destY, destWidth, destHeight);
      this.#createAndBindBuffers(gl, srcX, srcY, srcWidth, srcHeight);
    }
  
    #createIntermediaryTexturesAndFrameBuffers(counts, srcWidth, srcHeight) {
      const gl = this.gl;
  
      let textures = [];
      let frameBuffers = [];
  
      for (let i = 0; i < counts; ++i) {
        const texture = this.#createAndSetupTexture(gl);
        textures.push(texture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, srcWidth, srcHeight, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
  
        const frameBuffer = gl.createFramebuffer();
        frameBuffers.push(frameBuffer);
        gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
      }
  
      return { textures, frameBuffers };
    }
  
    #createAndBindBuffers(gl, x, y, width, height) {
      const positionBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
      this.#setRectangle(gl, x, y, width, height);
      const texcoordBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);
      gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
        0.0,  0.0,
        1.0,  0.0,
        0.0,  1.0,
        0.0,  1.0,
        1.0,  0.0,
        1.0,  1.0,
      ]), gl.STATIC_DRAW);
  
      gl.enableVertexAttribArray(this.program.attributes.a_position);
      gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  
      const size = 2;          // 2 components per iteration
      const type = gl.FLOAT;   // the data is 32bit floats
      const normalize = false; // don't normalize the data
      const stride = 0;        // 0 = move forward size * sizeof(type) each iteration to get the next position
      const offset = 0;        // start at the beginning of the buffer
      gl.vertexAttribPointer(this.program.attributes.a_position, size, type, normalize, stride, offset);
  
      gl.enableVertexAttribArray(this.program.attributes.a_texCoord);
      gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);
      gl.vertexAttribPointer(this.program.attributes.a_texCoord, size, type, normalize, stride, offset);
  
    }
  
    #setRectangle(gl, x, y, width, height) {
      var x1 = x;
      var x2 = x + width;
      var y1 = y;
      var y2 = y + height;
      gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
           x1, y1,
           x2, y1,
           x1, y2,
           x1, y2,
           x2, y1,
           x2, y2,
        ]), gl.STATIC_DRAW);
    }
  
  
    #createAndSetupTexture(gl) {
      const texture = gl.createTexture();
      gl.bindTexture(gl.TEXTURE_2D, texture);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
      return texture;      
    }
  
  }
  