/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { COLOUR_10, COLOUR_1000 } from './utils';

const Tooltip = ({ children, text, position = 'top', isDisabled }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  // Tooltip positioning styles
  const tooltipStyles = {
    visibility: showTooltip ? 'visible' : 'hidden',
    opacity: showTooltip && !isDisabled ? 1 : 0,
    transition: 'opacity 0.05s ease',
    position: 'absolute',
    zIndex: 100,
    backgroundColor: COLOUR_1000,
    color: COLOUR_10,
    textAlign: 'center',
    borderRadius: '5px',
    padding: '8px',
    fontSize: '14px',
    whiteSpace: 'nowrap', // Ensure the text doesn't wrap
    bottom: position === 'top' ? '100%' : undefined,
    top: position === 'bottom' ? '100%' : undefined,
    left: position === 'left' ? '-130%' : position === 'right' ? '130%' : '50%',
    transform: position === 'top' || position === 'bottom' ? 'translateX(-50%)' : undefined, // Center horizontally for top/bottom
    marginBottom: position === 'top' ? '10px' : undefined,
    marginTop: position === 'bottom' ? '10px' : undefined,
    marginLeft: position === 'left' || position === 'right' ? undefined : undefined,
  };

  const containerStyles = {
    position: 'relative',
    display: 'inline-block',
  };

  return (
    <div
      style={containerStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <span style={tooltipStyles}>{text}</span>
    </div>
  );
};

export default Tooltip;
