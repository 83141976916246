/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../config/firebase";
import { COLOUR_500 } from './utils';
import Spinner from './Spinner';

export const SampleImageSelector = ({ isMobile, onSourceImageChange }) => {
  const [samples, setSamples] = useState([]);
  const [thumbnailLoaded, setThumbnailLoaded] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      const folderRef = ref(storage, "samples");
      try {
        const result = await listAll(folderRef);
        let files = await Promise.all(
          result.items.map(async (item) => {
            const name = item.name.split(".")[0];
            const thumbnail = await getDownloadURL(item);
            const filename = item.name;
            return { name, thumbnail, filename };
          })
        );
        let fileMap = new Map();
        
        files.forEach(({ name, thumbnail, filename }) => {
          if (filename.endsWith(".400p.jpg")) {
            if (!fileMap.has(name)) {
              fileMap.set(name, { name, fullRes: null, thumbnail: null });
            }
            fileMap.get(name).thumbnail = thumbnail;
          } else {
            if (!fileMap.has(name)) {
              fileMap.set(name, { name, fullRes: null, thumbnail: null });
            }
            fileMap.get(name).fullRes = thumbnail;
          }
        });

        const newSamples = Array.from(fileMap.values());
        setSamples(newSamples);
        setThumbnailLoaded(new Array(newSamples.length).fill(false));
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchImages();
  }, []);

  return (
    <div
      style={{
        border: `1px solid ${COLOUR_500}`,
        width: isMobile ? '90%' : '60%',
        minWidth: '100px',
        height: isMobile ? '120px' : '150px',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        borderRadius: '10px',
        overflowX: 'auto',
        gap: 10,
        padding: 10
      }}
    >
      {
        samples.map(({name, thumbnail, filename, fullRes}, index) => (
          <div
            key={name}
            style={{
              position: "relative",
              width: isMobile ? "60px" : "100px",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Spinner/>
            <img
              id={name}
              src={thumbnail}
              key={name + `${index}`}
              alt={`Image from sample gallery named ${name}`}
              style={{
                height: '100%',
                width: isMobile ? "60px" : "100px",
                objectFit: "cover",
                objectPosition: "50% 50%",
                imageRendering: 'smooth',
                borderRadius: '5px',
                filter: hoveredIndex == null || hoveredIndex == index ? 'brightness(1.0)' : 'brightness(0.5)',
                transition: 'all 0.5s ease',
                cursor: hoveredIndex == null || hoveredIndex == index ? "pointer" : undefined,
                position: 'absolute'
              }}
              draggable={false}
              loading={'lazy'}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => onSourceImageChange({
                url: fullRes,
                name: name + '.jpg',
                type: 'image/jpeg'
              })}
            />
          </div>
        ))
      }
    </div>
  );
};

export default SampleImageSelector;