/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Spinner from "../Spinner";

const SampleColourMatchButton = ({ isDisabled, isMatchingColours, filterName, thumbnailSrc, onClick, isActive, width=120, widthOffset=20, height=220, heightOffsetPct="110%" }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = React.useRef(null);

  useEffect(() => {
    // IntersectionObserver for lazy loading
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the image is visible
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: (isHovered || isActive) && isImageLoaded && (!isDisabled || isMatchingColours) ? width + widthOffset : width,
        height: height,
        transition: "all 0.5s ease",
        overflow: "visible",
        cursor: isDisabled || isMatchingColours ? undefined : "pointer",
        position: "relative",
        boxShadow: isActive && isImageLoaded ? '0 0 30px rgb(0, 0, 255, 0.5)' : undefined,
        flexShrink: 0,
        opacity: (isDisabled && !isActive) ? 0.1 : undefined,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={imageRef} // Attach ref for lazy loading
    >
      {/* Thumbnail */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isVisible && (
          <>
            <img
              src={thumbnailSrc}
              alt={`Image of ${filterName} for color matching`}
              style={{
                width: "100%",
                height: (isHovered || isActive) && (!isDisabled || isMatchingColours) && isImageLoaded ? heightOffsetPct : "100%",
                transition: 'all 0.5s ease',
                objectFit: "cover",
                objectPosition: "50% 50%",
                display: isImageLoaded ? "block" : "none", // Show only when loaded
                imageRendering: 'smooth',
                pointerEvents: (isDisabled || isMatchingColours) ? "none" : undefined
              }}
              onClick={onClick}
              onLoad={() => setIsImageLoaded(true)} // Update state when image loads
              draggable={false}
              fetchPriority="high"
            />
            {!isImageLoaded && <Spinner />}
          </>
        )}
      </div>
    </div>
  );
};

export default SampleColourMatchButton;
