import { Helmet } from 'react-helmet';
import React from 'react';
import './HowItWorks.css'; // Import the external CSS file

export const HowItWorks = () => {
  return (
    <>
      <Helmet>
        <title>How It Works | Automated Photo Editing | Reka-Reka</title>
        <meta name="description" content="Discover how Reka-Reka works. Steal colors, apply automated color grading, and transform your images with automated tools in just a few clicks." />
        <meta name="keywords" content="how it works, automated photo editing, color grading, image editor, photo color tools, smart editing tools, image filters, social media sharing, photo enhancement, image retouching, auto color grading, color transformation, photo editing guide" />
        <link rel="canonical" href="https://reka-reka.com/how-it-works" />
        <meta property="og:title" content="How It Works | Automated Photo Editing | Reka-Reka" />
        <meta property="og:description" content="Step-by-step guide on how to use Reka-Reka. Steal colors, apply color grading, and create stunning photo edits effortlessly." />
        <meta property="og:image" content="https://reka-reka.com/logo.png" />
        <meta property="og:url" content="https://reka-reka.com/how-it-works" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="How It Works | Automated Photo Editing | Reka-Reka" />
        <meta name="twitter:description" content="Learn how to use Reka-Reka for automated photo editing. Steal colors and apply automated color grading with ease." />
        <meta name="twitter:image" content="https://reka-reka.com/logo.png" />
        <meta name="robots" content="index, follow" />
        <link rel="icon" href="https://reka-reka.com/favicon.ico" sizes="any" />
        <link rel="apple-touch-icon" href="https://reka-reka.com/logo.png" />
      </Helmet>

      <main className="how-it-works">
        <h1 className="title">How It Works</h1>

        <section className="section">
          <h2 className="subtitle">Color Grade Your Images with Smart Automation</h2>
          <p className="paragraph">
            With our intuitive image editor, transforming your photos has never been easier. Whether you&apos;re a designer, photographer, or casual creator, here&apos;s how you can use our app to elevate your images in just a few steps.
          </p>
        </section>

        <section className="step-section">
          <h3 className="step-title">Step 1: Upload Your Image or Choose from our Gallery</h3>
          <p className="paragraph">
            Start by uploading the image you want to edit. Simply drag and drop your file or click the upload button to select your photo. If you don't have one, you can use one of the images in our sample gallery.
          </p>
        </section>


        <section className="step-section">
          <h3 className="step-title">Step 2 (Optional): Steal Colors from a Reference Image</h3>
          <p className="paragraph">
            Want to match the color grading of another photo? Let our Smart Technology do the hard work.
            Our <strong>Auto Color Grading</strong> tool automatically adjusts colors, contrast, and tones to create a professional finish with one click.
            Use our <strong>&quot;Color Match&quot;</strong> feature:
          </p>
          <ul className="list">
            <li>Upload a reference image.</li>
            <li>Our Auto Color Match analyzes the colors and applies them to your image seamlessly.</li>
          </ul>
        </section>

        <section className="step-section">
          <h3 className="step-title">Step 3: Adjust Image Settings</h3>
          <p className="paragraph">Use our powerful editing sliders and color wheels to:</p>
          <ul className="list">
            <li><strong>Enhance Exposure and Contrast:</strong> Perfect the brightness and contrast of your image.</li>
            <li><strong>Saturate Colors:</strong> Make colors pop or tone them down for a muted look.</li>
            <li><strong>Color Grade with Ease:</strong> Apply professional-level color adjustments effortlessly using our color wheels and sliders.</li>
          </ul>
        </section>

        <section className="step-section">
          <h3 className="step-title">Step 4: Download Your Masterpiece</h3>
          <p className="paragraph">
            Once you&apos;re happy with your edits, download your final image in the same resolution as your uploaded image with no watermarks. On mobile, you can easily share to your social media or download to your Photos.
          </p>
        </section>

        <section className="section">
          <h2 className="subtitle">Why Use Our Tool?</h2>
          <ul className="list">
            <li><strong>Automated Editing:</strong> Save time and achieve results that rival professional tools.</li>
            <li><strong>No Learning Curve:</strong> Designed for everyone, from beginners to experts.</li>
            <li><strong>Fast and Efficient:</strong> Edit your images in seconds, not hours.</li>
          </ul>
          <p className="paragraph">
            Start creating stunning images today. <a className="link" href="/">Try it now for free!</a>
          </p>
        </section>
      </main>
    </>
  );
};

export default HowItWorks;
