import React from "react";
import { COLOUR_10, COLOUR_1000 } from "./utils";

export const Logo = ({isMobile=false}) => {
  const offset = isMobile ? 1.8 : 3.1;
  return (
    <div style={{position: 'relative', height: '100%', width: 200}}>
      <div
        style={{
          position: 'absolute',
          left: offset * 3,
          zIndex: 4,
        }}
      >
        <h1
          style={{
            fontSize: isMobile ? "12px" : "20px",
            color: COLOUR_10,
            fontFamily: 'Instrument Sans',
            fontWeight: 700,
            fontStyle: 'italic'
          }}
        >
          {isMobile ? "R" : "Reka-Reka"}
        </h1>
      </div>

      <div
        style={{
          position: 'absolute',
          left: offset * 2 + offset * 2,
          zIndex: 3,
          backgroundColor: COLOUR_1000
        }}
      >
        <h1
          style={{
            fontSize: isMobile ? "12px" : "20px",
            opacity: 0.0,
            fontFamily: 'Instrument Sans',
            fontWeight: 700,
            fontStyle: 'italic',
          }}
        >
          R
        </h1>
      </div>

      <div
        style={{
          position: 'absolute',
          left: offset * 2,
          zIndex: 2
        }}
      >
        <h1
          style={{
            fontSize: isMobile ? "12px" : "20px",
            color: 'rgba(0,0,255, 1.0)',
            fontFamily: 'Instrument Sans',
            fontWeight: 700,
            fontStyle: 'italic',
          }}
        >
          R
        </h1>
      </div>

      <div
        style={{
          position: 'absolute',
          left: offset,
          zIndex: 1
        }}
      >
        <h1
          style={{
            fontSize: isMobile ? "12px" : "20px",
            color: 'rgba(0,255,0,1.0)',
            fontFamily: 'Instrument Sans',
            fontWeight: 700,
            fontStyle: 'italic',
          }}
        >
          R
        </h1>
      </div>      

      <div
        style={{
          position: 'absolute',
          zIndex: 0
        }}
      >
        <h1
          style={{
            fontSize: isMobile ? "12px" : "20px",
            color: 'rgba(255,0,0,1.0)',
            fontFamily: 'Instrument Sans',
            fontWeight: 700,
            fontStyle: 'italic',
          }}
        >
          R
        </h1>
      </div>
    </div>
  );
};

export default Logo;
