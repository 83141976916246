import React, { cloneElement, useState } from 'react';
import { Color, COLOUR_10, COLOUR_1000, COLOUR_400, COLOUR_500, COLOUR_700, COLOUR_800, COLOUR_900, COLOUR_BLUE, COLOUR_BLUE_OPACITY } from '../utils';
import MobileColourMatching from './MobileColourMatching';
import ContrastSlider from '../sliders/ContrastSlider';
import ExposureSlider from '../sliders/ExposureSlider';
import SaturationSlider from '../sliders/SaturationSlider';
import ColourAdjustments from './colour/ColourAdjustments';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons';

const SunIcon = ({ size = 50, colour = COLOUR_10 }) => {
  const center = size / 2; // Center of the sun
  const rayLength = size * 0.2; // Length of rays (20% of size)
  const circleRadius = size * 0.2; // Radius of the sun circle (20% of size)

  return (
    <svg
      width={size * 1.2}
      height={size * 1.2}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Sun circle */}
      <circle
        cx={center}
        cy={center}
        r={circleRadius}
        fill={'rgba(0,0,0,0)'}
        stroke={colour}
        strokeWidth={1}
      />

      {/* Sun rays */}
      <line
        x1={center}
        y1={center - rayLength * 2 - 1}
        x2={center}
        y2={center - rayLength - 2}
        stroke={colour}
        strokeWidth="1"
      />
      <line
        x1={center}
        y1={center + rayLength + 2}
        x2={center}
        y2={center + rayLength * 2 + 1}
        stroke={colour}
        strokeWidth="1"
      />
      <line
        x1={center - rayLength * 2 - 1}
        y1={center}
        x2={center - rayLength - 2}
        y2={center}
        stroke={colour}
        strokeWidth="1"
      />
      <line
        x1={center + rayLength + 2}
        y1={center}
        x2={center + rayLength * 2 + 1}
        y2={center}
        stroke={colour}
        strokeWidth="1"
      />
      <line
        x1={center - rayLength * 1.5}
        y1={center - rayLength * 1.5}
        x2={center - rayLength}
        y2={center - rayLength}
        stroke={colour}
        strokeWidth="1"
      />
      <line
        x1={center + rayLength * 1.5}
        y1={center + rayLength * 1.5}
        x2={center + rayLength}
        y2={center + rayLength}
        stroke={colour}
        strokeWidth="1"
      />
      <line
        x1={center - rayLength * 1.5}
        y1={center + rayLength * 1.5}
        x2={center - rayLength}
        y2={center + rayLength}
        stroke={colour}
        strokeWidth="1"
      />
      <line
        x1={center + rayLength * 1.5}
        y1={center - rayLength * 1.5}
        x2={center + rayLength}
        y2={center - rayLength}
        stroke={colour}
        strokeWidth="1"
      />
    </svg>
  );
};

const ContrastIcon = ({size, colour=COLOUR_10}) => {
  return (
    <svg
      width={size * 1.5}
      height={size * 1.5}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
    <defs>
      <linearGradient id="contrast-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="black" />
        <stop offset="50%" stopColor="black" />
        <stop offset="50%" stopColor={colour} />
      </linearGradient>
    </defs>
    <circle 
      cx={size / 2} 
      cy={size / 2}
      r={size / 2 - 1}  /* Adjusted radius to fit stroke within the SVG boundary */
      fill="url(#contrast-gradient)" 
      stroke={colour}
      strokeWidth="1"
    />
  </svg>
  )
};

const ShadowIcon = ({size, colour=COLOUR_BLUE}) => {
  return (
    <svg
      width={size * 1.5}
      height={size * 1.5}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
    <defs>
      <linearGradient id="shadow-gradient" x1="0%" y1="100%" x2="100%" y2="0%">
        <stop offset="0%" stopColor={colour} />
        <stop offset="30%" stopColor={colour} />
        <stop offset="50%" stopColor="black"/>
      </linearGradient>
    </defs>
    <circle 
      cx={size / 2} 
      cy={size / 2}
      r={size / 2 - 1}  /* Adjusted radius to fit stroke within the SVG boundary */
      fill="url(#shadow-gradient)" 
      stroke={colour}
      strokeWidth="1"
    />
  </svg>
  )
};

const HighlightIcon = ({size, colour=COLOUR_BLUE}) => {
  return (
    <svg
      width={size * 1.5}
      height={size * 1.5}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
    <defs>
      <linearGradient id="highlight-gradient" x1="0%" y1="100%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="black" />
        <stop offset="50%" stopColor="black" />
        <stop offset="70%" stopColor={colour}/>
      </linearGradient>
    </defs>
    <circle 
      cx={size / 2} 
      cy={size / 2}
      r={size / 2 - 1}  /* Adjusted radius to fit stroke within the SVG boundary */
      fill="url(#highlight-gradient)" 
      stroke={colour}
      strokeWidth="1"
    />
  </svg>
  )
};

const MidtoneIcon = ({size, colour=COLOUR_BLUE}) => {
  return (
    <svg
      width={size * 1.5}
      height={size * 1.5}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
    <defs>
      <linearGradient id="midtone-gradient" x1="0%" y1="100%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="black"/>
        <stop offset="20%" stopColor="black"/>
        <stop offset="50%" stopColor={colour}/>
        <stop offset="80%" stopColor="black"/>
        <stop offset="100%" stopColor="black"/>
      </linearGradient>
    </defs>
    <circle
      cx={size / 2} 
      cy={size / 2}
      r={size / 2 - 1}  /* Adjusted radius to fit stroke within the SVG boundary */
      fill="url(#midtone-gradient)" 
      stroke={colour}
      strokeWidth="1"
    />
  </svg>
  )
};

const SaturationIcon = ({size, colour=COLOUR_10}) => {
  return (
    <svg
      width={size * 1.5}
      height={size * 1.5}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
    <defs>
      <linearGradient id="gradient" x1="0%" y1="100%" x2="0%" y2="0%">
        <stop offset="20%" stopColor="black" />
        <stop offset="80%" stopColor={colour} />
      </linearGradient>
    </defs>
    <circle 
      cx={size / 2}
      cy={size / 2}
      r={size / 2 - 1}  /* Adjusted radius to fit stroke within the SVG boundary */
      fill="url(#gradient)" 
      stroke={colour}
      strokeWidth="1"
    />
  </svg>
  );
};

export const Control = ({
  params,
  handleContrastChange,
  handleExposureChange,
  handleSaturationChange,
  handleColourChange,
  handleOffsetChange,
  onTargetChange,
  onDeleteUserImage,
  onUserImageLoading,
  onUserImageLoaded,
  targetImageThumbnail,
  updateHistory,
  isDisabled,
  isMatchingColours
}) => {
  const [activeControl, setActiveControl] = useState(0);
  const [isHoverOnControl, setIsHoverOnControl] = useState(null);

  const size = 14;
  const buttons = [
    {
      name: 'Colour Match (Auto)',
      icon: <FontAwesomeIcon icon={faEyeDropper} style={{fontSize: '15px', color: COLOUR_10}}/>
    },
    {
      name: 'Contrast',
      icon: <ContrastIcon size={size}/>
    },
    {
      name: 'Exposure',
      icon: <SunIcon size={size * 1.5} color={COLOUR_10}/>
    },
    {
      name: 'Saturation',
      icon: <SaturationIcon size={size}/>
    },
    {
      name: 'Shadows',
      icon: <ShadowIcon size={size}/>
    },
    {
      name: 'Midtones',
      icon: <MidtoneIcon size={size}/>
    },
    {
      name: 'Highlights',
      icon: <HighlightIcon size={size}/>
    }
  ];

  let control = null;

  if (activeControl === 0) {
    control = <MobileColourMatching
      isMatchingColours={isMatchingColours}
      isDisabled={isDisabled}
      targetImageThumbnail={targetImageThumbnail}
      params={params}
      onTargetChange={onTargetChange}
      onDeleteUserImage={onDeleteUserImage}
      onUserImageLoading={onUserImageLoading}
      onUserImageLoaded={onUserImageLoaded}
    />;
  }

  if (activeControl === 1) {
    control = (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ContrastSlider
          contrast={params.contrast}
          onChange={handleContrastChange}
          updateHistory={updateHistory}
          isDisabled={isDisabled}
        />
      </div>
    );
  }

  if (activeControl === 2) {
    control = (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ExposureSlider
          exposure={params.exposure}
          onChange={handleExposureChange}
          updateHistory={updateHistory}
          isDisabled={isDisabled}
        />
      </div>
    );
  }

  if (activeControl === 3) {
    control = (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <SaturationSlider
          saturation={params.saturation}
          onChange={handleSaturationChange}
          updateHistory={updateHistory}
          isDisabled={isDisabled}
        />
      </div>
    );
  }

  if (activeControl === 4) {
    control = (
      <ColourAdjustments
        key={"shadow"}
        params={params}
        handleColourChange={handleColourChange}
        handleOffsetChange={handleOffsetChange}
        type={"shadow"}
        updateHistory={updateHistory}
        isDisabled={isDisabled}
      />
    );
  }

  if (activeControl === 5) {
    control = (
      <ColourAdjustments
        key={"midtone"}
        params={params}
        handleColourChange={handleColourChange}
        handleOffsetChange={handleOffsetChange}
        type={"midtone"}
        updateHistory={updateHistory}
        isDisabled={isDisabled}
      />
    );
  }

  if (activeControl === 6) {
    control = (
      <ColourAdjustments
        key={"highlight"}
        params={params}
        handleColourChange={handleColourChange}
        handleOffsetChange={handleOffsetChange}
        type={"highlight"}
        updateHistory={updateHistory}
        isDisabled={isDisabled}
      />
    );
  }

  return (
    <div
      style={{
        backgroundColor: COLOUR_900,
        width: '100%',
        height: '55%',
        maxHeight: '275px'
      }}
    >
      <div
        style={{
          width: '100%',
          height: '65%'
        }}
      >
        {
          control
        }
      </div>

      <div
        style={{
          width: '100%',
          height: "5%",
          backgroundColor: COLOUR_1000,
          color: COLOUR_400,
          fontSize: '70%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {
          buttons[activeControl].name
        }
      </div>
      
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '30%',
          overflowX: 'scroll'
        }}
      >
        {
          buttons.map((button, index) => {
            return (
              <button
                key={index}
                style={{
                  outline: 'none',
                  border: 'none',
                  backgroundColor: activeControl === index ? COLOUR_BLUE_OPACITY : COLOUR_900,
                  color: COLOUR_10,
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer'
                }}
                onClick={() => setActiveControl(index)}
                onMouseEnter={() => setIsHoverOnControl(index)}
                onMouseLeave={() => setIsHoverOnControl(null)}
                aria-label={button.name}
              >
                { button.icon ? button.icon : button.name[0]}
              </button>
            )
          })
        }
      </div>
    </div>
  );
};

export default Control;
