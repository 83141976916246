import React, { useRef, useEffect, useState } from 'react';
import { ShaderFrame } from '../../webgl/ShaderProgram';

export const Canvas = ({ imgData, params, zoom }) => {
  const devicePixelRatio = window.devicePixelRatio || 1;
  const topLevelRef = useRef(null);
  const canvasRef = useRef(null);
  const shaderFrameRef = useRef(null);
  const [dimensions, setDimensions] = useState(null);
  
  useEffect(() => {
    const resizeObserver = new ResizeObserver(onResize);
    if (topLevelRef.current) {
      resizeObserver.observe(topLevelRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const onResize = () => {
    if (!topLevelRef.current) return;
    const { width, height } = topLevelRef.current.getBoundingClientRect();
    setDimensions({width, height});
  };

  const render = () => {
    if (!canvasRef.current) return;
    if (!dimensions) return;
  
    const canvas = canvasRef.current;
    const gl = canvas.getContext('webgl2', { preserveDrawingBuffer: false });

    const { width: canvasWidth, height: canvasHeight } = dimensions;

    if (!imgData) {
      gl.clearColor(0, 0, 0, 0);
      gl.clear(gl.COLOR_BUFFER_BIT);
      return;
    }

    if (!shaderFrameRef.current) {
      shaderFrameRef.current = new ShaderFrame(gl);
    }
    const shaderFrame = shaderFrameRef.current;

    const newCanvasWidth = canvasWidth * devicePixelRatio;
    const newCanvasHeight = canvasHeight * devicePixelRatio;

    const resize = canvas.width != newCanvasWidth || canvas.height != newCanvasHeight;

    if (resize) {
      canvas.width = newCanvasWidth;
      canvas.height = newCanvasHeight;
      canvas.style.width = `${canvasWidth}px`;
      canvas.style.height = `${canvasHeight}px`;
    }
      
    const aspectRatio = imgData.width / imgData.height;
    const maxHeight = canvasHeight;
    const maxWidth = canvasWidth;
    const targetDisplayWidth = aspectRatio > 1.0 ? maxWidth : maxHeight * aspectRatio;
    const targetDisplayHeight = aspectRatio > 1.0 ? maxWidth / aspectRatio : maxHeight;
  
    const x = (canvasWidth - targetDisplayWidth * Math.pow(zoom, 2)) / 2.0;
    const y = (canvasHeight - targetDisplayHeight * Math.pow(zoom, 2)) / 2.0;
  
    const { exposure, saturation, contrast } = params;
  
    shaderFrame.renderImage({
      srcs: [
        {
          element: imgData,
          fx: [
            {
              name: 'chain',
              exposure,
              saturation,
              contrast,
              shadowColor: [
                params.shadowParameters.colour.red,
                params.shadowParameters.colour.green,
                params.shadowParameters.colour.blue,
              ],
              shadowOffset: params.shadowParameters.offset,
              midtoneColor: [
                params.midtoneParameters.colour.red,
                params.midtoneParameters.colour.green,
                params.midtoneParameters.colour.blue,
              ],
              midtoneOffset: params.midtoneParameters.offset,
              highlightColor: [
                params.highlightParameters.colour.red,
                params.highlightParameters.colour.green,
                params.highlightParameters.colour.blue,
              ],
              highlightOffset: params.highlightParameters.offset,
            },
          ],
        },
      ],
      srcX: 0,
      srcY: 0,
      srcWidth: targetDisplayWidth * devicePixelRatio * zoom,
      srcHeight: targetDisplayHeight * devicePixelRatio * zoom,
      dstX: x * devicePixelRatio,
      dstY: y * devicePixelRatio,
      dstWidth: targetDisplayWidth * devicePixelRatio * Math.pow(zoom, 2),
      dstHeight: targetDisplayHeight * devicePixelRatio * Math.pow(zoom, 2),
      zoom,
    });
  }

  useEffect(() => {
    render();
  }, [imgData, params, zoom, dimensions, devicePixelRatio]);

  return (
    <div
      ref={topLevelRef}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex'
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
        }}
        aria-labelledby="canvasDescription"
        role="img"
      />
      <p id="canvasDescription" style={{display: 'none'}}>
        A real-time editor for automated color grading. Adjust the sliders to see live changes.
      </p>

    </div>
  );
};

export default Canvas;
