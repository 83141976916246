/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { COLOUR_10, COLOUR_200, COLOUR_600, COLOUR_700, COLOUR_950, COLOUR_BLUE_OPACITY } from './utils';
import SampleImageSelector from './SampleImageSelector';

export const DragAndDrop = ({ imgData, onChange, onSourceImageChange, onError }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.types[0] == "Files") {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file
      && file.type.startsWith("image/")
      &&
      (
        file.type.endsWith("jpg") ||
        file.type.endsWith("png") ||
        file.type.endsWith("jpeg") ||
        file.type.endsWith("webp")
      )
    ) {

      onSourceImageChange({
        url: URL.createObjectURL(file),
        name: file.name,
        type: file.type
      });
    } else {
      onError("Please drop a valid image file");
    }
    setIsDragging(false);
    setIsHover(false);
  };

  return (
    <div
    onDrop={handleDrop}
    onDragOver={handleDragOver}
    onDragLeave={handleDragLeave}
    style={{
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,
      backgroundColor: imgData ?
        "rgba(0, 0, 0, 0)"
        : isDragging ? COLOUR_BLUE_OPACITY : undefined,
      transition: 'all 0.5s ease'
    }}
  >
    {!imgData && 
    <div style={{gap: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
      <div
        style={{
          border: isDragging ? `1px solid ${COLOUR_10}` : `1px solid ${COLOUR_600}`,
          width: '50px',
          height: '80px',
          borderRadius: '5px',
          transition: 'all 0.5s ease',
          marginBottom: 20,
        }}
      >
      </div>
      <div style={{color: isDragging ? COLOUR_10 : COLOUR_600, cursor: 'default', fontSize: '16px', fontWeight: 500}}>Drop your image file here</div>
      <div style={{color: isDragging ? COLOUR_10 : COLOUR_600, cursor: 'default', fontSize: '16px', fontWeight: 500}}>or</div>
      <button
        id ="upload-image-button"
        style={{
          backgroundColor: isHover ? COLOUR_700 : 'transparent',
          border: isHover ? `1px solid ${COLOUR_950}` : `1px solid ${COLOUR_10}`,
          color: isHover ? COLOUR_200 : COLOUR_10,
          outline: 'none', 
          padding: '15px 20px',
          cursor: 'pointer',
          borderRadius: '5rem',
          fontSize: '16px',
          transition: 'all 0.5s ease',
          fontWeight: 500,
          marginTop: 20,
          }}
        onClick={() => document.getElementById("fileInput").click()}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        Choose File
      </button>
      <div style={{color: isDragging ? COLOUR_10 : COLOUR_600, cursor: 'default', fontSize: '16px', fontWeight: 500}}>or</div>
      <div style={{color: isDragging ? COLOUR_10 : COLOUR_600, cursor: 'default', fontSize: '16px', fontWeight: 500}}>Choose from gallery</div>
      <SampleImageSelector onSourceImageChange={onSourceImageChange} isMobile={false}/>
    </div>
      
    }
    <input
      id="fileInput"
      type="file"
      accept="image/*"
      style={{ display: "none" }}
      onChange={onChange}
    />
  </div>
  );
};

export default DragAndDrop;