
export class EffectsMode {
  static BYPASS = -1;
  static TRANSLATION = 0;
  static CONVOLUTION = 1;
  static BRIGHTNESS_CONTRAST = 2;
  static BGRA = 3;
  static WAVEFORM_LUM_SCOPE = 4;
  static VECTOR_YUV_SCOPE = 5;
  static HISTOGRAM_YUV_SCOPE = 6;
  static BLEND_NORMAL = 7;
  static BLEND_MAX = 8;
  static BLEND_MIN = 9;
  static COLOR_WHEEL = 10;
  static CHAIN = 11;

  static EBU_COLOR_BARS = 100;
}

export class KernelGenerator {
  static MAX_KERNEL_SIZE = 31 * 31;

  static gaussian(rows, cols, sigma=1.0) {
    if (rows * cols > KernelGenerator.MAX_KERNEL_SIZE) {
      throw new Error(`Kernel ${rows}x${cols} size is bigger than ${KernelGenerator.MAX_KERNEL_SIZE}`);
    }
    this.size = [rows, cols];
    this.rows = rows;
    this.cols = cols;

    let weight = 0.0;
    let kernels = [];
    const s = 2.0 * sigma * sigma;
    for (let i = 0; i < rows * cols; ++i) {
      const row = Math.floor(i / cols);
      const col = i % cols;

      const dx = col - Math.floor(cols / 2.0);
      const dy = row - Math.floor(rows / 2.0);

      const r = Math.sqrt(dx * dx + dy * dy);
      const kernel = Math.exp(-(r * r) / s) / (Math.PI * s);
      weight += kernel;
      kernels.push(kernel);
    }
    kernels = kernels.map((k) => k / weight);
    this.kernels = kernels;
    return this;
  }
}

class WebglFx {
  static MODE_BY_NAME = {
    // BRIGHTNESS: EffectsMode.BRIGHTNESS_CONTRAST,
    // CONTRAST: EffectsMode.BRIGHTNESS_CONTRAST,
    BLUR: EffectsMode.CONVOLUTION,
    OPACITY: EffectsMode.BLEND_NORMAL,
    COLOR_WHEEL: EffectsMode.COLOR_WHEEL,
    EBU_COLOR_BARS: EffectsMode.EBU_COLOR_BARS,
    LIGHT: EffectsMode.BRIGHTNESS_CONTRAST,
    CHAIN: EffectsMode.CHAIN
  };

  constructor(item) {
    const {name, ...params} = item;
    this.name = name;
    this.mode = WebglFx.MODE_BY_NAME[name.split(' ').join('_').toUpperCase()];
    this.params = params;

    switch (this.name.toLowerCase()) {
      // case 'brightness':
      //   this.fn = this.getBrightnessFn;
      //   break;
      // case 'contrast':
      //   this.fn = this.getContrastFn;
      //   break;
      case 'blur':
        this.fn = this.getBlurFn;
        break;
      case 'opacity':
        this.fn = this.getOpacityFn;
        break;
      case 'light':
        this.fn = this.getLightFn;
        break;
      case 'color wheel':
        this.fn = this.getColorWheelFn;
        break;
      case 'chain':
        this.fn = this.getChainFn;
        break;
      default:
        this.fn = () => {};
        break;
    }
  }

  getLightFn(gl, shaderProgram) {
    const paramsLocation = gl.getUniformLocation(shaderProgram.program, "u_params[0]");
    gl.uniform1fv(paramsLocation, [this.params.contrastValue, this.params.brightnessValue, this.params.exposureValue]);

    // const paramsLocation = gl.getUniformLocation(shaderProgram.program, "u_params[0]");
    // gl.uniform1fv(paramsLocation, [1.0, this.params.contrastValue]);
  }

  getBrightnessFn(gl, shaderProgram) {
    const paramsLocation = gl.getUniformLocation(shaderProgram.program, "u_params[0]");
    gl.uniform1fv(paramsLocation, [1.0, this.params.value]);
  }

  getContrastFn(gl, shaderProgram) {
    const paramsLocation = gl.getUniformLocation(shaderProgram.program, "u_params[0]");
    gl.uniform1fv(paramsLocation, [this.params.value, 0.0]);
  }

  getBlurFn(gl, shaderProgram) {
    const paramsLocation = gl.getUniformLocation(shaderProgram.program, "u_params[0]");
    // console.log(this.params)
    gl.uniform1fv(paramsLocation, [this.params.value, 1.0]);
    // const rowSize = (['both', 'vertical'].includes(this.params.direction)) ? this.params.size : 1;
    // const colSize = (['both', 'horizontal'].includes(this.params.direction)) ? this.params.size : 1;
    // const gaussianFilter = KernelGenerator.gaussian(rowSize, colSize);
    // gl.uniform1i(shaderProgram.uniforms.u_kernelRows, gaussianFilter.rows);
    // gl.uniform1i(shaderProgram.uniforms.u_kernelCols, gaussianFilter.cols);

    // const kernelLocation = gl.getUniformLocation(shaderProgram.program, "u_kernel[0]");
    // gl.uniform1fv(kernelLocation, gaussianFilter.kernels);
  }

  getOpacityFn(gl, shaderProgram) {
    switch (this.params.blendMode) {
      case 'normal':
        this.mode = EffectsMode.BLEND_NORMAL;
        break;
      case 'lighten':
        this.mode = EffectsMode.BLEND_MAX;
        break;
      case 'darken':
        this.mode = EffectsMode.BLEND_MIN;
        break;
      default:
        break;
    }
    gl.uniform1i(shaderProgram.uniforms.u_mode, this.mode); // override
    const paramsLocation = gl.getUniformLocation(shaderProgram.program, "u_params[0]");
    gl.uniform1fv(paramsLocation, [this.params.value]);
  }

  getColorWheelFn(gl, shaderProgram) {
    const paramsLocation = gl.getUniformLocation(shaderProgram.program, "u_params[0]");
    gl.uniform1fv(paramsLocation, [...this.params.shadowColor, this.params.shadowOffset, ...this.params.midtoneColor, this.params.midtoneOffset, ...this.params.highlightColor, this.params.highlightOffset]);
  }

  getChainFn(gl, shaderProgram) {
    const paramsLocation = gl.getUniformLocation(shaderProgram.program, "u_params[0]");
    gl.uniform1fv(paramsLocation, [
      this.params.exposure,
      this.params.saturation,
      this.params.contrast,
      ...this.params.shadowColor,
      this.params.shadowOffset,
      ...this.params.midtoneColor,
      this.params.midtoneOffset,
      ...this.params.highlightColor,
      this.params.highlightOffset
    ]);
  }
}


// Given SequenceFx = [...] of N arrays,
// we want to convert into WebglFx = [...] of M arrays
// Webgl could not hold large data which restricts
// the size of a convolution kernel radius
// A workaround is to stack kernels that is equivalent
// to the large kernel size
export class EffectsManager {
  constructor(sequenceFx, res=360) {
    this.sequenceFx = sequenceFx;
    this.height = res;
    this.webglFx = this.#convertToWebglFx(sequenceFx);
  }

  #convertToWebglFx(sequenceFx) {
    const webglFx = sequenceFx
      .reduce((webglFx, seqFx) => {
        // if (seqFx.name == 'blur') {
          // const targetKernelSize = linear(seqFx.value, 0.0, 1.0, 0.0, 0.1 * this.height);
          // const sizes = this.#calcKernelSizes(targetKernelSize, Math.sqrt(KernelGenerator.MAX_KERNEL_SIZE), 3);
          // webglFx.push(...sizes.map((size) => { return {...seqFx, size}} ))
          // webglFx.push({...seqFx, size: 3})
        // } else {
          webglFx.push(seqFx)
        // }
        return webglFx;
      }, [])
      .map((item) => new WebglFx(item));

    const opacityIndex = webglFx.findIndex((fx) => fx.name == 'opacity');

    if (opacityIndex > -1) {
      return [
        ...webglFx.reduce((out, fx, i) => {
          if (i !== opacityIndex) {
            out.push(fx);
          }
          return out;
        }, []),
        webglFx[opacityIndex]
        ]
    }

    return webglFx;
  }
}


