export const bypass = () => {
    return `
      vec4 bypass(sampler2D image, vec2 coord) {
        return texture2D(image, coord);
      }
    `;
}
  
  export const blendNormal = () => {
    return `
      vec4 blendNormal(vec4 topColor, vec4 bottomColor, float opacity) {
        topColor.a *= opacity;
        vec4 mixin = topColor;
        vec4 srcColor = (1.0 - bottomColor.a) * topColor + bottomColor.a * mixin;
        return topColor.a * srcColor + bottomColor.a * bottomColor * (1.0 - topColor.a);
      }
    `;
  }
  
  export const blendLighten = () => {
    return `
      vec4 blendLighten(vec4 top, vec4 bottom, float opacity) {
        vec4 lightenColor = vec4(
          max(top.r, bottom.r),
          max(top.g, bottom.g),
          max(top.b, bottom.b),
          top.a
        );
        return blendNormal(lightenColor, bottom, opacity);
      }
    `;  
  }
  
  export const blendDarken = () => {
    return `
      vec4 blendDarken(vec4 top, vec4 bottom, float opacity) {
        vec4 darkenColor = vec4(
          min(top.r, bottom.r),
          min(top.g, bottom.g),
          min(top.b, bottom.b),
          top.a
        );
        return blendNormal(darkenColor, bottom, opacity);
      }
    `;  
  }
  
  export const isPositionOutside = () => {
    return `
      bool isPositionOutside(vec2 pos) {
        return pos.x < 0.0 || pos.y < 0.0 || pos.x > 1.0 || pos.y > 1.0;
      }
    `;
  }
