/* eslint-disable react/react-in-jsx-scope */
import { NavBar } from './components/NavBar';
import { HowItWorks } from './components/HowItWorks';

import { BrowserRouter as Router, Routes, Route } from 'react-router';
import Help from './components/Help';
import LandingPage from './components/LandingPage';
import useMediaQuery from './components/useMediaQuery';
import { ImageEditor } from './components/ImageEditor';
import { AppProvider } from './AppProvider';
import Privacy from './components/Privacy';
import TermsAndConditions from './components/TermsAndConditions';

function App() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <Router>
      <AppProvider>
      <Routes>
        {/* Main */}
        <Route
          path="/"
          element={
          <div style={{display: 'flex', flexDirection: 'column', height: '100dvh'}}>
            <NavBar isMobile={isMobile}/>
            <ImageEditor isMobile={isMobile}/>
          </div>
          }
        />

        {/* How it works */}
        <Route
          path="/how-it-works"
          element={
            <div style={{display: 'flex', flexDirection: 'column', height: '100dvh'}}>
              <NavBar isMobile={isMobile}/>
              <HowItWorks isMobile={isMobile}/>
            </div>
          }
        />

        {/* Help */}
        <Route
          path="/landing"
          element={
            <div style={{display: 'flex', flexDirection: 'column', height: '100dvh'}}>
              <NavBar isMobile={isMobile}/>
              <LandingPage isMobile={isMobile}/>
            </div>
          }
        />

        {/* Help */}
        <Route
          path="/help"
          element={
            <div style={{display: 'flex', flexDirection: 'column', height: '100dvh'}}>
              <NavBar isMobile={isMobile}/>
              <Help isMobile={isMobile}/>
            </div>
          }
        />

        {/* Privacy */}
        <Route
          path="/privacy"
          element={
            <div style={{display: 'flex', flexDirection: 'column', height: '100dvh'}}>
              <NavBar isMobile={isMobile}/>
              <Privacy isMobile={isMobile}/>
            </div>
          }
        />

        {/* Terms */}
        <Route
          path="/terms"
          element={
            <div style={{display: 'flex', flexDirection: 'column', height: '100dvh'}}>
              <NavBar isMobile={isMobile}/>
              <TermsAndConditions isMobile={isMobile}/>
            </div>
          }
        />

      </Routes>
      </AppProvider>
    </Router>
  );
}

export default App;
