import React, { useState, useRef, useEffect, useContext } from 'react';

import { Helmet } from "react-helmet";
import { COLOUR_1000, COLOUR_950 } from '../utils';
import { DEFAULT_COLOUR_PARAMETERS, DEFAULT_CONTRAST, DEFAULT_EXPOSURE, DEFAULT_SATURATION, DEFAULT_ZOOM } from '../matchUtils';

import Footer from './Footer';
import Control from './Control';
import Display from './Display';

import FloatingTopPanel from './FloatingTopPanel';
import { AppContext } from '../../AppContext';
import DownloadModal from '../DownloadModal';

export const MobileImageEditor = ({
  sourceImage,
  targetImageThumbnail,
  imgData,
  params,
  history,
  undo,
  redo,
  isDisabled,
  isMatchingColours,
  handleSourceImageUpload,
  handleContrastChange,
  handleExposureChange,
  handleSaturationChange,
  handleParameterChange,
  handleOffsetChange,
  handleColourChange,
  updateHistory,
  onSourceImageChange,
  onTargetChange,
  onRemoveImage,
  onDeleteUserImage,
  onUserImageLoading,
  onUserImageLoaded
}) => {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const { error, isShowDownload } = useContext(AppContext);
  return (
    <>
      <main
        style={{
          display: "flex",
          flexDirection: 'column',
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100dvh",
          overflow: 'hidden',
          position: 'relative'
        }}      
      >
        <div
          style={{
            width: '100%'
          }}
        >
          <FloatingTopPanel
            history={history}
            isImageLoaded={imgData != null}
            undo={undo}
            redo={redo}
            isDisabled={isDisabled}
            error={error}
            sourceImage={sourceImage}
            params={params}
            onRemoveImage={onRemoveImage}
          />
        </div>
        <Display imgData={imgData} onChange={handleSourceImageUpload} onSourceImageChange={onSourceImageChange} params={params} zoom={zoom}/>
        <Control
          params={params}
          handleContrastChange={handleContrastChange}
          handleExposureChange={handleExposureChange}
          handleSaturationChange={handleSaturationChange}
          handleColourChange={handleColourChange}
          handleOffsetChange={handleOffsetChange}
          isDisabled={isDisabled}
          updateHistory={updateHistory}
          onTargetChange={onTargetChange}
          onDeleteUserImage={onDeleteUserImage}
          onUserImageLoading={onUserImageLoading}
          onUserImageLoaded={onUserImageLoaded}
          targetImageThumbnail={targetImageThumbnail}
          isMatchingColours={isMatchingColours}
        />
        {
          isShowDownload
          &&
          <DownloadModal isMobile={true} isDisabled={isDisabled}/>
        }
      </main>
    </>
  );
};
