import React, { useState } from 'react';
import { COLOUR_10, COLOUR_950, COLOUR_700, COLOUR_200, COLOUR_600 } from '../utils';
import Canvas from './Canvas';
import SampleImageSelector from '../SampleImageSelector';

export const Display = ({imgData, onChange, params, zoom, onSourceImageChange}) => {
  const [isUploadButtonHover, setisUploadButtonHover] = useState(false);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: COLOUR_950,
          width: '100%',
          height: '100%'
        }}
      >
        {
          imgData ?
          <Canvas imgData={imgData} params={params} zoom={zoom}/>
          :
          (
          <div
            style={{
              paddingTop: '20%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              gap: 20
            }}
          >
          <button
            id ="upload-image-button"
            style={{
                backgroundColor: isUploadButtonHover ? COLOUR_700 : 'transparent',
                border: isUploadButtonHover ? `1px solid ${COLOUR_950}` : `1px solid ${COLOUR_10}`,
                color: isUploadButtonHover ? COLOUR_200 : COLOUR_10,
                outline: 'none', 
                padding: '15px 20px',
                cursor: 'pointer',
                borderRadius: '5rem',
                fontSize: '16px',
                transition: 'all 0.5s ease',
                fontWeight: 500,
                }}
              onClick={() => document.getElementById("fileInput").click()}
              onMouseEnter={() => setisUploadButtonHover(true)}
              onMouseLeave={() => setisUploadButtonHover(false)}
            >
            Choose File
          </button>
          <div style={{color: COLOUR_600, cursor: 'default', fontSize: '16px', fontWeight: 500}}>or</div>
          <div style={{color: COLOUR_600, cursor: 'default', fontSize: '16px', fontWeight: 500}}>Choose from gallery</div>
          <SampleImageSelector onSourceImageChange={onSourceImageChange} isMobile={true}/>
          </div>
          )
        }
      </div>
      <input
      id="fileInput"
      type="file"
      accept="image/*"
      style={{ display: "none" }}
      onChange={onChange}
      />
    </>
  );
};

export default Display;
