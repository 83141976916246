/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import Tooltip from './Tooltip';
import { COLOUR_10, COLOUR_700, COLOUR_800, COLOUR_825, COLOUR_950 } from './utils';

export const FloatingBottomPanel = ({ zoom, onChange, onRemoveImage, isDisabled }) => {
  const [isHovered, setIsHovered] = useState(false);

  const thumbColour = COLOUR_950;
  const thumbBorder = `2px solid ${COLOUR_800}`;
  const height = '30px'; 
  const minZoom = 0.5;
  const maxZoom = 2.0;
  const handleMouseUpForZoom = () => {
    const snapAt = 1.0;
    const offset = 0.05;
    if (zoom < snapAt + offset && zoom > snapAt - offset) {
      onChange(snapAt);
    }
  };

  return (
    <div
      style={{
        display: 'flex', // Use flexbox for alignment
        alignItems: 'center', // Vertically center items
      }}
    >
      {/* "-" Div */}
      <Tooltip text="Zoom out" position="top">
        <button
            style={{
              padding: '5px 10px',
              backgroundColor: 'transparent',
              color: COLOUR_10,
              borderRadius: '4px',
              textAlign: 'center',
              width: height, // Adjusted width to fit the text comfortably
              height: height, // Height for consistency with other buttons
              display: 'flex',
              alignItems: 'center', // Vertically center the text
              justifyContent: 'center', // Horizontally center the text
              lineHeight: height, // Ensure vertical alignment
              border: 'none',
              outline: 'none',
              cursor: 'pointer'
            }}
            onClick={(e) => {
              e.preventDefault();
              onChange(Math.max(zoom - 0.01, minZoom)); // Decrease zoom
            }}
          >
            -
        </button>
      </Tooltip>

      {/* Slider */}
      <input
        id="zoom-slider"
        type="range"
        min={minZoom}
        max={maxZoom}
        step="0.01"
        value={zoom}
        onChange={(e) => onChange(Number(e.target.value))}
        onMouseUp={handleMouseUpForZoom}
        style={{
          margin: '0 5px',
          flex: 1,
          backgroundColor: COLOUR_825,
          height: "5px",
          borderRadius: "5px",
          appearance: "none",
          WebkitAppearance: "none",
          outline: "none",
          width: "90%",
          cursor: "pointer",
        }}
      />

      {/* "+" Div */}
      <Tooltip text="Zoom in" position="top">
        <button
          style={{
            padding: '5px 10px',
            backgroundColor: 'transparent',
            color: COLOUR_10,
            borderRadius: '4px',
            textAlign: 'center',
            width: height, // Adjusted width to fit the text comfortably
            height: height, // Height for consistency with other buttons
            display: 'flex',
            alignItems: 'center', // Vertically center the text
            justifyContent: 'center', // Horizontally center the text
            lineHeight: height, // Ensure vertical alignment
            border: 'none',
            outline: 'none',
            cursor: 'pointer'
          }}
          onClick={(e) => {
            e.preventDefault();
            onChange(Math.min(zoom + 0.01, maxZoom));
          }}
        >
          +
        </button>
      </Tooltip>
      <Tooltip text="Resets zoom" position="top">
        <button
          style={{
            padding: '5px 10px',
            backgroundColor: 'transparent',
            color: COLOUR_10,
            borderRadius: '4px',
            textAlign: 'center',
            width: '20px', // Adjusted width to fit the text comfortably
            height: height, // Height for consistency with other buttons
            display: 'flex',
            alignItems: 'center', // Vertically center the text
            justifyContent: 'center', // Horizontally center the text
            lineHeight: height, // Ensure vertical alignment
            border: 'none',
            outline: 'none',
            cursor: 'pointer'
          }}
          onClick={() => onChange(1.0)}
        >
          <div><FontAwesomeIcon icon={faRotateRight} style={{fontSize: '10px'}}/></div>
        </button>
      </Tooltip>
      {/* Zoom Level Display */}
      <button
          style={{
            padding: '5px 10px',
            backgroundColor: 'transparent',
            color: COLOUR_10,
            borderRadius: '4px',
            textAlign: 'center',
            width: '50px', // Adjusted width to fit the text comfortably
            height: height, // Height for consistency with other buttons
            display: 'flex',
            alignItems: 'center', // Vertically center the text
            justifyContent: 'center', // Horizontally center the text
            lineHeight: height, // Ensure vertical alignment
            border: 'none',
            outline: 'none',
            cursor: 'default',
            fontSize: '14px'
          }}
          // onClick={(e) => onChange(1.0)}
        >
          <div>{Math.round(zoom * 100)}%</div>
        </button>

      {/* Delete */}
      <Tooltip text="Removes image" position="top" isDisabled={isDisabled}>
        <button
          style={{
            padding: '5px 10px',
            backgroundColor: 'transparent',
            color: isDisabled ? COLOUR_700 : isHovered ? 'rgb(200, 0, 2)' : COLOUR_10,
            borderRadius: '4px',
            textAlign: 'center',
            width: '20px', // Adjusted width to fit the text comfortably
            height: height, // Height for consistency with other buttons
            display: 'flex',
            alignItems: 'center', // Vertically center the text
            justifyContent: 'center', // Horizontally center the text
            lineHeight: height, // Ensure vertical alignment
            border: 'none',
            outline: 'none',
            cursor: isDisabled ? undefined : 'pointer',
            transition: 'all 0.1s ease'
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() =>  setIsHovered(false)}
          onClick={onRemoveImage}
          disabled={isDisabled}
        >
          <div><FontAwesomeIcon icon={faXmark}/></div>
        </button>
      </Tooltip>

      <style>
        {`
          /* Webkit browsers (Chrome, Safari, Edge) */
          #zoom-slider::-webkit-slider-thumb {
            appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          /* Firefox */
          #zoom-slider::-moz-range-thumb {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          /* For IE (if necessary) */
          #zoom-slider::-ms-thumb {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          #zoom-slider::-webkit-slider-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }

          #zoom-slider::-moz-range-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }

          #zoom-slider::-ms-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }
        `}
      </style>
    </div>
  );
};

export default FloatingBottomPanel;
