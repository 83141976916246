/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { COLOUR_1000, COLOUR_BLUE } from './utils';
import { AppContext } from '../AppContext';

export const DownloadButton = ({ isDisabled }) => {
  const [hoverOnDownload, setHoverOnDownload] = useState(false);
  const { setIsShowDownload } = useContext(AppContext);
  return (
    <>
      <button
        id="download-image-button-to-open-modal"
        style={{
          backgroundColor: COLOUR_BLUE,
          border: 'none',
          color: COLOUR_1000,
          outline: 'none',
          padding: hoverOnDownload ? '15px 30px' : '15px 20px',
          cursor: isDisabled ? undefined : 'pointer',
          borderRadius: '5rem',
          fontSize: '14px',
          transition: 'all 0.5s ease',
          fontWeight: 500,
          zIndex: 3,
          marginRight: 10,
          opacity: isDisabled ? 0.4 : 1
        }}
        onMouseEnter={() => setHoverOnDownload(true)}
        onMouseLeave={() => setHoverOnDownload(false)}
        disabled={isDisabled}
        onClick={() => setIsShowDownload(true)}
      >
        <div style={{display: 'flex', gap: 10}}>
          Download
        </div>
      </button>
    </>
  );
};

export default DownloadButton;
