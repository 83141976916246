import React, { useState } from "react";
import { Link } from "react-router"; // Import Link from React Router
import { COLOUR_10, COLOUR_1000 } from "./utils";
import Logo from "./Logo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

export const NavBar = ({ isMobile = false }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const styles = {
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: isMobile ? undefined : "20px 20px",
      paddingLeft: isMobile ? 10 : 20,
      backgroundColor: isMobile ? 'rgb(0,0,0)' : COLOUR_1000,
    },
    hamburger: {
      color: COLOUR_10,
      maxHeight: '100%',
      background: "none",
      border: "none",
      cursor: "pointer",
      display: isMobile ? "flex" : "none",
      width: isMenuOpen ? undefined : 30,
      height: 20,
      alignItems: 'center',
      justifyContent: isMenuOpen ? 'start' : 'center',
    },
    nav: {
      display: "flex",
      gap: "20px",
    },
    navLink: {
      textDecoration: "none",
      color: COLOUR_10,
      fontWeight: "bold",
      fontSize: isMobile ? "12px" : "14px",
      whiteSpace: "nowrap",
    },
    navOpen: {
      display: isMobile && isMenuOpen ? "flex" : "none",
      flexDirection: "column",
      gap: "15px",
      position: "absolute",
      height: '100%',
      top: "0px",
      right: "0%",
      backgroundColor: COLOUR_1000,
      padding: "30px 20px",
      zIndex: 1000,
    },
  };

  return (
    <header style={styles.header}>
      <Logo isMobile={isMobile} />

      {/* Hamburger Menu for Mobile */}
      { 
        isMobile 
        &&
        <button
        style={styles.hamburger}
        onClick={toggleMenu}
        aria-label="Toggle navigation menu"
        >
          <FontAwesomeIcon icon={faBars} style={{ fontSize: '10px', paddingBottom: 5}} />
        </button>
      }
      

      {/* Links */}

      <nav style={{ ...(isMobile ? styles.navOpen : styles.nav) }}>
        { 
          <button
            style={{...styles.hamburger, display: isMobile ? "flex" : "none", paddingBottom: 10}}
            onClick={toggleMenu}
            aria-label="Toggle navigation menu"
            >
              <FontAwesomeIcon icon={faArrowRightLong} style={{ fontSize: '100%' }} />
          </button>
        }
        <Link to="/" style={styles.navLink} onClick={toggleMenu}>
          Create
        </Link>
        <Link to="/how-it-works" style={styles.navLink} onClick={toggleMenu}>
          How it works
        </Link>
        <Link to="/landing" style={styles.navLink} onClick={toggleMenu}>
          Landing
        </Link>
        <Link to="/help" style={styles.navLink} onClick={toggleMenu}>
          Help
        </Link>
        <Link to="/privacy" style={styles.navLink} onClick={toggleMenu}>
          Privacy
        </Link>
        <Link to="/terms" style={styles.navLink} onClick={toggleMenu}>
          Terms & Conditions
        </Link>
      </nav>
    </header>
  );
};

export default NavBar;
