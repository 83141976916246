/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, useRef } from 'react';
import { COLOUR_10, COLOUR_700, COLOUR_850 } from '../utils';
import { ColorWheelPickerComponent } from './ColorWheelPickerComponent';

export const ColorWheelFxControlComponent = ({
  params,
  onParameterChange,
  onColourChange,
  onOffsetChange,
  updateHistory,
  isDisabled
}) => {
  const [isSmall, setIsSmall] = useState(false);
  const parentDivRef = useRef(null);

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      const width = entry.contentRect.width;
      setIsSmall(width < 300);
    });

    if (parentDivRef.current) {
      observer.observe(parentDivRef.current);
    }

    return () => {
      if (parentDivRef.current) {
        observer.unobserve(parentDivRef.current);
      }
    };
  }, []);

  return (
    <div ref={parentDivRef}>
      <div
        style={{
          color: COLOUR_10,
          padding: '15px',
          borderBottom: `1px solid ${COLOUR_700}`,
          fontSize: '16px',
          fontWeight: 700,
          backgroundColor: COLOUR_850,
          cursor: 'default',
          userSelect: 'none',
          WebkitUserSelect: 'none',
          msUserSelect: 'none'
        }}
      >
        Color Wheels
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: isSmall ? '1fr' : '50% 50%', // Adjust layout based on width
        }}
      >
        <ColorWheelPickerComponent
          name="shadow"
          colour={params.shadowParameters.colour}
          offset={params.shadowParameters.offset}
          onParameterChange={onParameterChange}
          onColourChange={onColourChange}
          onOffsetChange={onOffsetChange}
          updateHistory={updateHistory}
          isDisabled={isDisabled}
        />
        <ColorWheelPickerComponent
          name="midtone"
          colour={params.midtoneParameters.colour}
          offset={params.midtoneParameters.offset}
          onParameterChange={onParameterChange}
          onColourChange={onColourChange}
          onOffsetChange={onOffsetChange}
          updateHistory={updateHistory}
          isDisabled={isDisabled}
        />
      </div>
      <div style={{
        width: isSmall ? undefined : '50%',
        marginInline: isSmall ? undefined : 'auto',
        display: isSmall ? 'grid' : undefined,
        gridTemplateColumns: isSmall ? '1fr' : undefined
      }}>
        <ColorWheelPickerComponent
          name="highlight"
          colour={params.highlightParameters.colour}
          offset={params.highlightParameters.offset}
          onParameterChange={onParameterChange}
          onColourChange={onColourChange}
          onOffsetChange={onOffsetChange}
          updateHistory={updateHistory}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};
