import { Helmet } from "react-helmet";

const Privacy = ({isMobile}) => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Reka-Reka</title>
        <meta 
          name="description" 
          content="Read the Reka-Reka Privacy Policy to understand how we handle data, analytics, and payments through Buy Me a Coffee." 
        />
        <meta 
          name="keywords" 
          content="privacy policy, data collection, user privacy, Reka-Reka privacy, analytics, Buy Me a Coffee policy" 
        />
        <link rel="canonical" href="https://reka-reka.com/privacy" />
        <meta property="og:title" content="Privacy Policy | Reka-Reka" />
        <meta property="og:description" content="Learn about how Reka-Reka manages data, cookies, and third-party services like Google Analytics and Buy Me a Coffee." />
        <meta property="og:image" content="https://reka-reka.com/logo.png" />
        <meta property="og:url" content="https://reka-reka.com/privacy" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy | Reka-Reka" />
        <meta name="twitter:description" content="Find out how Reka-Reka protects your privacy, including data collection and third-party services used." />
        <meta name="twitter:image" content="https://reka-reka.com/logo.png" />
        <meta name="robots" content="index, follow" />
        <link rel="icon" href="https://reka-reka.com/favicon.ico" sizes="any" />
        <link rel="apple-touch-icon" href="https://reka-reka.com/logo.png" />
      </Helmet>

  
      <main className="how-it-works">
        <div>
          <h1 className="title">Privacy Policy</h1>
          <p className="paragraph"><strong>Effective Date:</strong> 5 February 2025</p>

          <p className="paragraph">Welcome to Reka-Reka ("we," "our," or "us"). Your privacy is important to us, and this policy explains how we handle data when you use our website.</p>

          <div style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">1. Information We Collect</h2>
            <p className="paragraph">We do <strong>not</strong> collect any personal information such as names, emails, or passwords since our website does not have a login or signup system. However, we may collect certain non-personal data through third-party services.</p>

            <h3 className="paragraph">a. Google Analytics</h3>
            <p className="paragraph">We use <strong>Google Analytics</strong> to track how users interact with our website. Google Analytics may collect:</p>
            <ul className="paragraph">
              <li><strong>Usage Data:</strong> Pages visited, time spent on the site, actions taken (e.g., downloads).</li>
              <li><strong>Device Data:</strong> Browser type, operating system, and screen resolution.</li>
              <li><strong>Location Data:</strong> Approximate location based on IP address (we do not store exact locations).</li>
            </ul>
            <p className="paragraph">Google may use this data as described in their <a className="link" href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. You can opt out using the <a className="link" href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out Browser Add-on</a>.</p>
          </div>

          <section style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">2. User-Uploaded Images</h2>
            <p className="paragraph">We do <strong>not</strong> store, process, or share any images that you upload. All processing happens within your browser, and once you leave the site, the images are no longer accessible.</p>
          </section>

          <section style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">3. Payments via Buy Me a Coffee</h2>
            <p className="paragraph">We use <strong>Buy Me a Coffee</strong> to accept voluntary donations. If you choose to support us:</p>
            <ul className="paragraph">
              <li>Your payment will be processed by <strong>Buy Me a Coffee</strong>, not us.</li>
              <li>We do <strong>not</strong> collect or store any financial details.</li>
              <li>Buy Me a Coffee may collect your payment details as per their <a className="link" href="https://www.buymeacoffee.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</li>
            </ul>
          </section>

          <section style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">4. Cookies & Tracking Technologies</h2>
            <p className="paragraph">We may use cookies to enhance your experience. These are primarily used by Google Analytics to measure website performance. You can disable cookies through your browser settings.</p>
          </section>

          <section style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">5. Third-Party Links</h2>
            <p className="paragraph">Our website may contain links to third-party services (e.g., Buy Me a Coffee). We are not responsible for their privacy policies, so we encourage you to read their terms before using their services.</p>
          </section>


          {
            /* <h2>6. Contact Us</h2>
            <p>If you have any questions about this policy, you can reach out to us at <strong>[Your Email Address]</strong>.</p> */
          }
          
          <section style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">6. Changes to This Policy</h2>
            <p className="paragraph">We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated <strong>"Effective Date"</strong>.</p>
          </section>
        </div>
      </main>

    </>
  );
};

export default Privacy;
