/* eslint-disable react/prop-types */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { Color, COLOUR_10, COLOUR_400, COLOUR_700, COLOUR_800, COLOUR_825, COLOUR_950 } from '../../utils';

export const SaturationSlider = ({
  isDisabled,
  hue,
  value,
  minValue=0.0,
  maxValue=1.0,
  defaultValue=0.0,
  onChange,
  updateHistory,
  prefix="shadow",
  label="Saturation",
  unit="%"
}) => {
  const thumbColour = COLOUR_950;
  const thumbBorder = `2px solid ${COLOUR_800}`;


  const endBackgroundColour = Color.fromHsv(hue, 1.0, 1.0);
  const background = `linear-gradient(to right, rgb(255,255,255), ${endBackgroundColour.toString()}`;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: "0px 10px",
        gap: 3
      }}
    >
      <div style={{fontSize: '16px', fontWeight: 500, color: COLOUR_10, minWidth: 80 }}>
          {label}
        </div>
      <input
        id={`${prefix}-${label}-slider`}
        type="range"
        min={minValue}
        max={maxValue}
        step="0.05"
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        onMouseDown={updateHistory}
        style={{
          height: "5px",
          borderRadius: "5px",
          appearance: "none",
          WebkitAppearance: "none",
          outline: "none",
          width: "90%",
          cursor: "pointer",
          background,
        }}
        disabled={isDisabled}
      />
      <button
        style={{
          outline: 'none',
          border: 'none',
          backgroundColor: 'transparent',
          marginRight: 3,
          color: COLOUR_700,
          cursor: 'pointer',
        }}
        onClick={() => {
          onChange(defaultValue);
          updateHistory();
        }}
        disabled={isDisabled}
      >
        <FontAwesomeIcon icon={faRotateRight} style={{ fontSize: '100%' }} />
      </button>
      <div
        style={{
          fontSize: '16px',
          fontWeight: 500,
          color: COLOUR_10,
          minWidth: `${16 * 0.7 * 4}px`, // Reserve space for 3-digit percentages
          textAlign: 'right', // Align the text to the right
        }}
      >
        {Math.round(value * 100) + unit}
      </div>
      <style>
        {`
          /* Webkit browsers (Chrome, Safari, Edge) */
          #${prefix}-${label}-slider::-webkit-slider-thumb {
            appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          /* Firefox */
          #${prefix}-${label}-slider::-moz-range-thumb {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          /* For IE (if necessary) */
          #${prefix}-${label}-slider::-ms-thumb {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          #${prefix}-${label}-slider::-webkit-slider-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }

          #${prefix}-${label}-slider::-moz-range-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }

          #${prefix}-${label}-slider::-ms-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }
        `}
      </style>
    </div>
  );
};

export default SaturationSlider;
