import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOUR_10, COLOUR_900 } from "./utils";

export const AutoLogo = ({}) => {
  return (
    <div
      style={{
        width: 60,
        height: '100%',
        gap: 2,
        border: '1px solid #0000',
        borderRadius: '20px',
        background: `linear-gradient(${COLOUR_900}, ${COLOUR_900}) padding-box, linear-gradient(
          45deg,
          rgb(255, 29, 99), #3020ff
        ) border-box`,
        
        fontSize: "12px",
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: "center",
        userSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        cursor: "default",
        color: COLOUR_10
      }}
    >
    <FontAwesomeIcon icon={faBolt} style={{ color: "rgb(250, 250, 0)", fontSize: '10px' }} /> Auto
    </div>
  );
};