/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { toResolution } from './matchUtils';

export const ImageLoader = ({ sourceImage, targetImage, onSourceImageChange, onTargetImageChange, onError }) => {
  const offscreenCanvasRef = useRef(null);

  const loadImageData = (imageUrl, canvasRef, resolution=undefined) => {
    return new Promise((resolve) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d', { willReadFrequently: true});
      ctx.imageSmoothingEnabled = true;
      const img = new Image();
      img.crossOrigin = "anonymous";
  
      img.src = imageUrl;
      img.onload = () => {
        const { width, height } = (isFinite(resolution)) ? toResolution(img.width, img.height, resolution) : img;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        const imageData = ctx.getImageData(0, 0, width, height);
        resolve(imageData);
      };
      img.onerror = () => {
        onError("The selected file is not a valid image.");
        URL.revokeObjectURL(imageUrl);
      };
    });
  };

  const createPreview = async () => {
    if (!sourceImage) return;
    const newImgData = await loadImageData(sourceImage.url, offscreenCanvasRef, 720);
    const newImgDataForMatching = await loadImageData(sourceImage.url, offscreenCanvasRef, 360);
    onSourceImageChange(newImgData, newImgDataForMatching);
  };

  const createTargetPreview = async () => {
    if (!targetImage) return;
    const newTargetImgData = await loadImageData(targetImage, offscreenCanvasRef, 360);
    onTargetImageChange(newTargetImgData);
  };

  useEffect(() => {
    if (!sourceImage) return;
    createPreview();
  }, [sourceImage]);

  useEffect(() => {
    if (!targetImage) return;
    createTargetPreview();
  }, [targetImage]);

  return (
    <canvas ref={offscreenCanvasRef} style={{ display: 'none' }}></canvas>
  );
}

export default ImageLoader;