/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';
import { ShaderFrame } from '../webgl/ShaderProgram';

export const CanvasDisplay = ({ imgData, params, zoom, dimensions }) => {
  const devicePixelRatio = window.devicePixelRatio || 1;
  const canvasRef = useRef(null);
  const shaderFrameRef = useRef(null);
  const { canvasWidth, canvasHeight } = dimensions;

  const render = () => {
    if (!canvasRef.current) return;
  
    const canvas = canvasRef.current;
    const gl = canvas.getContext('webgl2', { preserveDrawingBuffer: false });

    if (!imgData) {
      gl.clearColor(0, 0, 0, 0);
      gl.clear(gl.COLOR_BUFFER_BIT);
      return;
    }

    if (!shaderFrameRef.current) {
      shaderFrameRef.current = new ShaderFrame(gl);
    }
    const shaderFrame = shaderFrameRef.current;

    const newCanvasWidth = canvasWidth * devicePixelRatio;
    const newCanvasHeight = canvasHeight * devicePixelRatio;

    const resize = canvas.width != newCanvasWidth || canvas.height != newCanvasHeight;

    if (resize) {
      canvas.width = newCanvasWidth;
      canvas.height = newCanvasHeight;
      canvas.style.width = `${canvasWidth}px`;
      canvas.style.height = `${canvasHeight}px`;
    }
      
    const aspectRatio = imgData.width / imgData.height;
    const maxHeight = canvasHeight;
    const maxWidth = canvasWidth;
    const targetDisplayWidth = aspectRatio > 1.0 ? maxWidth : maxHeight * aspectRatio;
    const targetDisplayHeight = aspectRatio > 1.0 ? maxWidth / aspectRatio : maxHeight;
  
    const x = (canvasWidth - targetDisplayWidth * Math.pow(zoom, 2)) / 2.0;
    const y = (canvasHeight - targetDisplayHeight * Math.pow(zoom, 2)) / 2.0;
  
    const { exposure, saturation, contrast } = params;
  
    shaderFrame.renderImage({
      srcs: [
        {
          element: imgData,
          fx: [
            {
              name: 'chain',
              exposure,
              saturation,
              contrast,
              shadowColor: [
                params.shadowParameters.colour.red,
                params.shadowParameters.colour.green,
                params.shadowParameters.colour.blue,
              ],
              shadowOffset: params.shadowParameters.offset,
              midtoneColor: [
                params.midtoneParameters.colour.red,
                params.midtoneParameters.colour.green,
                params.midtoneParameters.colour.blue,
              ],
              midtoneOffset: params.midtoneParameters.offset,
              highlightColor: [
                params.highlightParameters.colour.red,
                params.highlightParameters.colour.green,
                params.highlightParameters.colour.blue,
              ],
              highlightOffset: params.highlightParameters.offset,
            },
          ],
        },
      ],
      srcX: 0,
      srcY: 0,
      srcWidth: targetDisplayWidth * devicePixelRatio * zoom,
      srcHeight: targetDisplayHeight * devicePixelRatio * zoom,
      dstX: x * devicePixelRatio,
      dstY: y * devicePixelRatio,
      dstWidth: targetDisplayWidth * devicePixelRatio * Math.pow(zoom, 2),
      dstHeight: targetDisplayHeight * devicePixelRatio * Math.pow(zoom, 2),
      zoom,
    });
  }

  useEffect(() => {
    render();
  }, [imgData, params, dimensions, zoom, devicePixelRatio]);

  return (
    <>
      <canvas
      ref={canvasRef}
      width={canvasWidth * devicePixelRatio}
      height={canvasHeight * devicePixelRatio}
      style={{
        width: `${canvasWidth}px`,
        height: `${canvasHeight}px`,
      }}
      aria-labelledby="canvasDescription"
      role="img"
    ></canvas>
    <p id="canvasDescription">
      A real-time editor for automated color grading. Adjust the sliders to see live changes.
    </p>
    </>
  );
};

export default CanvasDisplay;