import React, { useState, useRef, useEffect, useContext } from 'react';
import ImageLoader from './ImageLoader';

import { Helmet } from "react-helmet";
import DesktopImageEditor from './DesktopImageEditor';
import { MobileImageEditor } from './mobile/MobileImageEditor';
import { AppContext } from '../AppContext';

export const ImageEditor = ({ isMobile=false }) => {
  const {
    sourceImage,
    setSourceImage,
    targetImage,
    setTargetImage,
    targetImageThumbnail,
    setTargetImageThumbnail,
    imgData,
    setImgData,
    imgDataForMatching,
    setImgDataForMatching,
    targetImgData,
    setTargetImgData,
    isMatchingColours,
    setIsMatchingColours,
    isMatchingColourUserImageLoading,
    setIsMatchingColourUserImageLoading,
    isDownloading,
    setIsDownloading,
    params,
    setParams,
    history,
    setHistory,
    error,
    setError
  } = useContext(AppContext);

  const workerRef = useRef(null);
  const isDisabled = isMatchingColours || isMatchingColourUserImageLoading || !imgData || isDownloading;

  // Initialize the worker
  useEffect(() => {
    workerRef.current = new Worker(new URL('./imageWorker.js', import.meta.url));

    // Handle the message received from the worker
    workerRef.current.onmessage = (e) => {
      //params is prev settings  but filter
      const newParams = {...params, ...e.data.params, filter: e.data.filter};
      setParams(newParams);
    
      // If we select different filter quickly it will ruint the active states
      // maybe disable clicking while waiting for results
      // use set timeout to delay this a bit to avoid users from
      // switching to another filter quickly
      // setTimeout(() => setIsMatchingColours(false), 20);

      // I think I solved it
      setIsMatchingColours(false);
    };

    return () => {
      workerRef.current.terminate();      
    };
  }, []);

  // Handle source and target image uploads
  const handleSourceImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSourceImage({
        url: URL.createObjectURL(file),
        name: file.name,
        type: file.type
      });
    }
  };

  const onTargetChange = (filter) => {
    if (params.filter?.name == filter.name) return;
    setIsMatchingColours(!!imgData);
    setParams({
      ...params,
      filter
    });
    updateHistory();
    if (filter.name === 'user') {
      setTargetImageThumbnail(filter.thumbnail);
    }
    setTargetImage(filter.thumbnail);
  };

  useEffect(() => {
    if (!imgDataForMatching || !targetImgData) return;
    setIsMatchingColours(true);
    workerRef.current.postMessage({ srcData: imgDataForMatching, targetData: targetImgData, filter: params.filter });
  }, [imgDataForMatching, targetImgData]);

  const handleSaturationChange = (newSaturation) => {
    setParams({
      ...params,
      saturation: newSaturation
    });
  }

  const handleExposureChange = (newExposure) => {
    setParams({
      ...params,
      exposure: newExposure
    });
  }

  const handleContrastChange = (newContrast) => {
    setParams({
      ...params,
      contrast: newContrast
    });
  };

  const undo = () => {
    let { past, future } = history;
    if (past.length == 0) return;
    const previous = past[past.length - 1];
    const newPast = past.slice(0, -1);
    const newFuture = [params, ...future];
    const newParams = {
      ...previous
    };
    setParams(newParams);
    setHistory({
      past: newPast,
      future: newFuture
    });
  }

  const redo = () => {
    let { past, future } = history;
    if (future.length == 0) return;
    const next = future[0];
    const newPast = [...past, params];
    const newFuture = future.slice(1);
    const newParams = {
      ...next
    };
    setParams(newParams);
    setHistory({
      past: newPast,
      future: newFuture
    });
  }
  
  const updateHistory = () => {
    const { past } = history;
    if (past[past.length - 1] == params) return;
    setHistory({
      past: [...past, params],
      future: []
    });
  };

  const handleColourChange = (name, colour) => {
    if (name == "shadow") {
      setParams({
        ...params,
        shadowParameters: {
          colour: {
            red: colour.r,
            green: colour.g,
            blue: colour.b
          },
          offset: params.shadowParameters.offset
        }
      });
    } else if (name == "midtone") {
      setParams({
        ...params,
        midtoneParameters: {
          colour: {
            red: colour.r,
            green: colour.g,
            blue: colour.b
          },
          offset: params.midtoneParameters.offset
        }
      });
    } else if (name == "highlight") {
      setParams({
        ...params,
        highlightParameters: {
          colour: {
            red: colour.r,
            green: colour.g,
            blue: colour.b
          },
          offset: params.highlightParameters.offset
        }
      });
    }
  };

  const handleOffsetChange = (name, offset) => {
    if (name == "shadow") {
      setParams({
        ...params,
        shadowParameters: {
          colour: params.shadowParameters.colour,
          offset
        }
      });
    } else if (name == "midtone") {
      setParams({
        ...params,
        midtoneParameters: {
          colour: params.midtoneParameters.colour,
          offset
        }
      });
    } else if (name == "highlight") {
      setParams({
        ...params,
        highlightParameters: {
          colour: params.highlightParameters.colour,
          offset
        }
      });
    }
    
  };

  const handleParameterChange = (name, colour, offset) => {
    if (name == "shadow") {
      setParams({
        ...params,
        shadowParameters: {
          colour: {
            red: colour.r,
            green: colour.g,
            blue: colour.b
          },
          offset
        }
      });
    } else if (name == "midtone") {
      setParams({
        ...params,
        midtoneParameters: {
          colour: {
            red: colour.r,
            green: colour.g,
            blue: colour.b
          },
          offset
        }
      });
    } else if (name == "highlight") {
      setParams({
        ...params,
        highlightParameters: {
          colour: {
            red: colour.r,
            green: colour.g,
            blue: colour.b
          },
          offset
        }
      });
    }
  };



  return (
    <>
      <Helmet>
        <title>Create | Automated Color Grading Made Easy | Reka-Reka</title>
        <meta name="description" content="Reka-Reka's smart editor lets you apply automated color grading, steal colors, and create stunning edits with smart technology. Edit your images effortlessly for free!" />
        <meta name="keywords" content="photo editor, color grading, automated photo editing, image editor, photo color tools, smart editing tools, image filters, social media sharing, photo enhancement, image retouching, smart photo editing, image editing tools, automated editing, color tools" />
        <link rel="canonical" href="https://reka-reka.com/" />
        <meta property="og:title" content="Create | Automated Color Grading Made Easy | Reka-Reka" />
        <meta property="og:description" content="Effortlessly edit photos with Reka-Reka's smart editor. Steal colors, apply automated color grading, and create stunning photo edits for free!" />
        <meta property="og:image" content="https://reka-reka.com/logo.png" />
        <meta property="og:url" content="https://reka-reka.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Create | Automated Color Grading Made Easy | Reka-Reka" />
        <meta name="twitter:description" content="Edit your photos seamlessly with our smart technology on Reka-Reka. Steal colors, apply automated grading, and create stunning edits for free!" />
        <meta name="twitter:image" content="https://reka-reka.com/logo.png" />
        <meta name="robots" content="index, follow" />
        <link rel="icon" href="https://reka-reka.com/favicon.ico" sizes="any" />
        <link rel="apple-touch-icon" href="https://reka-reka.com/logo.png" />
      </Helmet>

      <>
      {
        isMobile ?
        <MobileImageEditor
          sourceImage={sourceImage}
          targetImageThumbnail={targetImageThumbnail}
          imgData={imgData}
          params={params}
          history={history}
          undo={undo}
          redo={redo}
          isDisabled={isDisabled}
          isMatchingColours={isMatchingColours}
          handleSourceImageUpload={handleSourceImageUpload}
          handleContrastChange={handleContrastChange}
          handleExposureChange={handleExposureChange}
          handleSaturationChange={handleSaturationChange}
          handleParameterChange={handleParameterChange}
          handleOffsetChange={handleOffsetChange}
          handleColourChange={handleColourChange}
          updateHistory={updateHistory}
          onSourceImageChange={setSourceImage}
          onTargetChange={onTargetChange}
          onRemoveImage={() => {
            setSourceImage(null);
            setImgData(null);
            setImgDataForMatching(null);
          }}
          onDeleteUserImage={() => {
            URL.revokeObjectURL(targetImage);
            setTargetImage(null);
            setTargetImgData(null);

            // If the current param is user
            // save the params but delete the
            // filter
            if (params.filter?.name == "user") {
              setParams({...params, filter: null});
            }
          }}
          onUserImageLoading={() => setIsMatchingColourUserImageLoading(true)}
          onUserImageLoaded={() => setIsMatchingColourUserImageLoading(false)}
        />
        :
        <DesktopImageEditor
          sourceImage={sourceImage}
          targetImageThumbnail={targetImageThumbnail}
          imgData={imgData}
          params={params}
          history={history}
          undo={undo}
          redo={redo}
          isDisabled={isDisabled}
          isMatchingColours={isMatchingColours}
          handleSourceImageUpload={handleSourceImageUpload}
          handleContrastChange={handleContrastChange}
          handleExposureChange={handleExposureChange}
          handleSaturationChange={handleSaturationChange}
          handleParameterChange={handleParameterChange}
          handleOffsetChange={handleOffsetChange}
          handleColourChange={handleColourChange}
          updateHistory={updateHistory}
          onSourceImageChange={setSourceImage}
          onTargetChange={onTargetChange}
          onRemoveImage={() => {
            setSourceImage(null);
            setImgData(null);
            setImgDataForMatching(null);
          }}
          onDeleteUserImage={() => {
            URL.revokeObjectURL(targetImage);
            setTargetImage(null);
            setTargetImgData(null);

            // If the current param is user
            // save the params but delete the
            // filter
            if (params.filter?.name == "user") {
              setParams({...params, filter: null});
            }
          }}
          onUserImageLoading={() => setIsMatchingColourUserImageLoading(true)}
          onUserImageLoaded={() => setIsMatchingColourUserImageLoading(false)}
        />

      }
      <ImageLoader 
        sourceImage={sourceImage}
        targetImage={targetImage}
        onSourceImageChange={(newImgData, newImgDataForMatching) => {
          setImgData(newImgData);
          setImgDataForMatching(newImgDataForMatching);
        }}
        onTargetImageChange={(newImgData) => setTargetImgData(newImgData)}
        onError={(msg) => {
          setError(msg);
          setParams(history.past[history.past.length - 1]);
          setHistory({
            past: history.past.slice(0, -1),
            future: history.future
          });
          setIsMatchingColours(false);
          setIsMatchingColourUserImageLoading(false);
          setTimeout(() => setError(null), 5000);
        }}
      />
      </>
    </>
  );
};
