/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useRef } from 'react';
import { toResolution } from './matchUtils';
import { AppContext } from '../AppContext';

export const CanvasDownloader = ({ download=false, toShare, onFinished }) => {
  const canvasRef = useRef(null);
  const sourceCanvasRef = useRef(null);

  const { sourceImage, params, setIsDownloading } = useContext(AppContext);

  const getFinalRenderFilename = (filename) => {
    const split = filename.split(".")
    return split.slice(0, -1).join(".") + '.final.' + split[split.length - 1];
  };

  const filename = sourceImage?.name ?  getFinalRenderFilename(sourceImage.name): '';

  const handleDownload = async () => {
    setIsDownloading(true);
    const imgData = await loadImageData(sourceImage.url, sourceCanvasRef);
    renderInWorker(imgData, filename, sourceImage.type, toShare);
  };

  useEffect(() => {
    if (download) {
      handleDownload();
    }
  }, [download]);

  const loadImageData = (imageUrl, canvasRef, resolution = undefined) => {
    return new Promise((resolve) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      ctx.imageSmoothingEnabled = true;
      const img = new Image();
      img.crossOrigin = "anonymous";

      img.src = imageUrl;
      img.onload = () => {
        const { width, height } = (isFinite(resolution)) ? toResolution(img.width, img.height, resolution) : img;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        const imageData = ctx.getImageData(0, 0, width, height);
        resolve(imageData);
      };
    });
  };

  const shareImage = async (blob, filename, type) => {
    try {
        if (!blob) {
            console.log("No valid blob to share.");
            return;
        }

        const file = new File([blob], filename, { type });

        if (!navigator.canShare || !navigator.canShare({ files: [file] })) {
            console.log("Sharing not supported on this device.");
            return;
        }

        await navigator.share({
            files: [file],
            title: 'Color Graded Image',
            text: 'Check out this image!',
        });

    } catch (error) {
        console.error('Sharing failed', error);
    } finally {
        setIsDownloading(false);
    }
};


  // Move rendering logic into a Web Worker
  const renderInWorker = (imgData, filename, type, toShare) => {
    const worker = new Worker(new URL('./workers/finalRenderWorker.js', import.meta.url)); // Import Worker file

    worker.onmessage = (e) => {
      const { blob } = e.data;
      worker.terminate();
      if (toShare) {
        shareImage(blob, filename, type);
      } else {
        const dataURL = URL.createObjectURL(blob);
        setIsDownloading(false);
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = filename;
        link.click();
      }
      onFinished();
    };

    // Post data (image data and parameters) to the worker
    worker.postMessage({ imgData, params, type });
  };

  return (
    <>
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      <canvas ref={sourceCanvasRef} style={{ display: 'none' }}></canvas>
    </>
  );
};

export default CanvasDownloader;
