import { Color } from "./utils";

export const CONTRAST_EPS = 1e-5;
export const CONTRAST_LOG_MIDPOINT = 0.18;
export const SATURATION_LUMA_WEIGHTS = [0.25, 0.5, 0.25];
export const ASPECT_RATIO_THRESHOLD = 16 / 9;
export const DEFAULT_LUT = Array.from({ length: 256 }, (_, i) => i / 255);
export const DEFAULT_LUTS = {
  red: DEFAULT_LUT,
  green: DEFAULT_LUT,
  blue: DEFAULT_LUT
};

export const DEFAULT_COLOUR_PARAMETERS = {
  colour: { red: 1.0, green: 1.0, blue: 1.0 },
  offset: 0
};

export const DEFAULT_SATURATION = 1.0;
export const DEFAULT_EXPOSURE = 0.0;
export const DEFAULT_CONTRAST = 1.0;
export const DEFAULT_ZOOM = 1.0;


export const toResolution = (width, height, resolution=720) => {
  const aspectRatio = width / height;

  let outHeight = 0;
  let outWidth = 0;
  if (aspectRatio < ASPECT_RATIO_THRESHOLD) {
    outHeight = resolution;
    outWidth = Math.round(aspectRatio * outHeight);
  } else {
    outWidth = Math.round(resolution * ASPECT_RATIO_THRESHOLD);
    outHeight = Math.round(outWidth / aspectRatio);
  }

  return { width: outWidth, height: outHeight }
}

export const median = (array) => {
  if (array.length === 0) {
    throw new Error("Array is empty");
  }
  const sorted = [...array].sort((a, b) => a - b);
  const mid = Math.floor(array.length / 2.0);
  const result = (array.length % 2) ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2.0;
  return result;
}

export const mean = (array) => {
  if (array.length === 0) {
    throw new Error("Array is empty");
  }
  return array.reduce((sum, val) => sum + val, 0) / array.length;
}

export const histogram = (data) => {
  const redHistogram = Array(256).fill(0);
  const greenHistogram = Array(256).fill(0);
  const blueHistogram = Array(256).fill(0);
  for (let i = 0; i < data.length; i += 4) {
    const r = data[i];     // Red channel
    const g = data[i + 1]; // Green channel
    const b = data[i + 2]; // Blue channel

    // Increment the corresponding histogram bins
    redHistogram[r]++;
    greenHistogram[g]++;
    blueHistogram[b]++;
  }

  return {
    red: redHistogram,
    green: greenHistogram,
    blue: blueHistogram
  }
}

export const cumulativeDistributionFunction = (data) => {

  const hist = histogram(data);

  let cdf = {
    red: hist.red,
    green: hist.green,
    blue: hist.blue
  }

  for (let i = 1; i < 256; ++i) {
    cdf.red[i] = cdf.red[i] + cdf.red[i - 1];
    cdf.green[i] = cdf.green[i] + cdf.green[i - 1];
    cdf.blue[i] = cdf.blue[i] + cdf.blue[i - 1];
  }

  const numPixels = data.length / 4;
  for (let i = 0; i < 256; ++i) {
    cdf.red[i] /= numPixels;
    cdf.green[i] /= numPixels;
    cdf.blue[i] /= numPixels;
  }

  return cdf;
}

export const interp = (x, xp, fp, left = null, right = null) => {
  const lenXp = xp.length;
  const lenFp = fp.length;

  if (lenXp === 0) throw new Error("xp array cannot be empty");
  if (lenFp !== lenXp) throw new Error("xp and fp arrays must be the same length");

  // Ensure arrays are sorted in ascending order
  if (!xp.every((val, i, arr) => i === 0 || arr[i - 1] <= val)) {
    throw new Error("xp array must be sorted in ascending order");
  }

  const result = [];

  // Iterate over each x value to interpolate
  for (let i = 0; i < x.length; i++) {
    const xVal = x[i];

    // Handle values below the range
    if (xVal < xp[0]) {
      result.push(left !== null ? left : fp[0]);
      continue;
    }

    // Handle values above the range
    if (xVal > xp[lenXp - 1]) {
      result.push(right !== null ? right : fp[lenFp - 1]);
      continue;
    }

    // Binary search to find the interval in xp that xVal falls into
    let low = 0;
    let high = lenXp - 1;
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);

      if (xp[mid] <= xVal && xVal <= xp[mid + 1]) {
        // Linear interpolation
        let slope = (fp[mid + 1] - fp[mid]) / (xp[mid + 1] - xp[mid]);
        slope = (isFinite(slope)) ? slope : 0.0;
        const interpolatedValue = fp[mid] + slope * (xVal - xp[mid]);
        result.push(interpolatedValue);
        break;
      } else if (xVal < xp[mid]) {
        high = mid - 1;
      } else {
        low = mid + 1;
      }
    }
  }

  return result;
}

export const getLuts = (srcCdf, dstCdf) => {
  const lut = interp(srcCdf, dstCdf, Array.from({ length: dstCdf.length }, (_, i) => i / 255.0));
  return lut;
}

export const estimateLift = (luts) => {
  if (luts[0] > 0.01) {
    return luts[0];
  }

  // Step 2: Generate the x array (0 to 1, 256 values)
  const x = Array.from({ length: 256 }, (_, i) => i / 255);

  // Step 3: Find valid indices
  const validIndices = x.reduce((acc, value, index) => {
    if (value < 0.15) acc.push(index);
    return acc;
  }, []);

  if (validIndices.length == 0) return 0.0;

  // Step 4: Calculate slope and y_intercept
  const lutsDiff = validIndices.slice(1).map((i, j) => luts[validIndices[j + 1]] - luts[validIndices[j]]);
  const xDiff = validIndices.slice(1).map((i, j) => x[validIndices[j + 1]] - x[validIndices[j]]);
  const slope = median(lutsDiff.reduce((acc, val, index) => {
    acc.push(val / xDiff[index]);
    return acc;
  }, []));

  const yIntercept = mean(validIndices.reduce((acc, val) => {
    acc.push(luts[val] - slope * x[val]);
    return acc;
  }, []));

  return yIntercept;
}

export const estimateGain = (luts) => {
  // Step 1: If the last element in luts < 0.99, return luts[luts.length - 1]
  if (luts[luts.length - 1] < 0.99) {
    return luts[luts.length - 1];
  }

  // Step 2: Generate the x array (0 to 1, 256 values)
  const x = Array.from({ length: 256 }, (_, i) => i / 255);

  // Step 3: Reverse and flip luts
  const revLuts = luts.map(value => 1 - value).reverse();

  // Step 4: Find valid indices
  const validIndices = x.reduce((acc, value, index) => {
    if (value < 0.15) acc.push(index);
    return acc;
  }, []);

  if (validIndices.length == 0) return 0.0;

  // Step 4: Calculate slope and y_intercept
  const lutsDiff = validIndices.slice(1).map((i, j) => revLuts[validIndices[j + 1]] - revLuts[validIndices[j]]);
  const xDiff = validIndices.slice(1).map((i, j) => x[validIndices[j + 1]] - x[validIndices[j]]);
  const slope = median(lutsDiff.reduce((acc, val, index) => {
    acc.push(val / xDiff[index]);
    return acc;
  }, []));

  const yIntercept = mean(validIndices.reduce((acc, val) => {
    acc.push(revLuts[val] - slope * x[val]);
    return acc;
  }, []));

  // Step 6: Calculate the final gain value
  return 1 - yIntercept;
}

export const estimateLuts = (srcCdf, targetCdf) => {
  return {
    red: getLuts(srcCdf.red, targetCdf.red),
    green: getLuts(srcCdf.green, targetCdf.green),
    blue: getLuts(srcCdf.blue, targetCdf.blue)
  }
}

export const estimateLifts = (luts) => {
  return {
    red: estimateLift(luts.red),
    green: estimateLift(luts.green),
    blue: estimateLift(luts.blue)
  }
}

export const estimateGains = (luts) => {
  return {
    red: estimateGain(luts.red),
    green: estimateGain(luts.green),
    blue: estimateGain(luts.blue)
  }
}

export const estimateGamma = (lut, gain, lift) => {
  let gamma = lut.reduce((out, lutValue, index) => {
    if (lutValue <= 0.01 || lutValue >= 0.99) return out;
    let value = Math.log((lutValue - lift) / (gain - lift));
    if (isFinite(value)) {
      if (index == 0) return out;
      value /= Math.log(index / 255);
      if (!isFinite(value)) return out;
      out.push(value);
    }
    return out;
  }, []);
  gamma = gamma.reduce((a, b) => a + b, 0) / gamma.length;
  gamma = (isNaN(gamma)) ? 0.0 : gamma;
  return gamma;
}

export const estimateGammas = (luts, gains, lifts) => {
  return {
    red: estimateGamma(luts.red, gains.red, lifts.red),
    green: estimateGamma(luts.green, gains.green, lifts.green),
    blue: estimateGamma(luts.blue, gains.blue, lifts.blue)
  };
}

export const adjustColourToLightness = (colour, lightness=0.5) => {
  const currentLightness = (Math.max(...Object.values(colour)) + Math.min(...Object.values(colour))) / 2.0;
  const deltaLightness = lightness - currentLightness;
  const newColour = {
    red: colour.red + deltaLightness,
    green: colour.green + deltaLightness,
    blue: colour.blue + deltaLightness
  };
  return newColour;
};

export const estimateShadowParameters = (lifts) => {
  const minimumLiftValue = Math.min(...Object.values(lifts));
  let colour = {
    red: lifts.red - minimumLiftValue,
    green: lifts.green - minimumLiftValue,
    blue: lifts.blue - minimumLiftValue
  }
  const avg = getAverageColour(colour);
  let offset = ((lifts.red - colour.red + avg) + (lifts.green - colour.green + avg) + (lifts.blue - colour.blue + avg)) / 3.0;
  
  colour.red = clamp(colour.red, 0, 1);
  colour.green = clamp(colour.green, 0, 1);
  colour.blue = clamp(colour.blue, 0, 1);
  offset = clamp(offset, -1, 1);

  colour = adjustColourToLightness(colour);
  colour.red = clamp(colour.red, 0, 1);
  colour.green = clamp(colour.green, 0, 1);
  colour.blue = clamp(colour.blue, 0, 1);
  return { colour, offset };
}

export const estimateHighlightParameters = (gains) => {
  const minimumGainValue = Math.min(...Object.values(gains));
  let colour = {
    red: gains.red - minimumGainValue,
    green: gains.green - minimumGainValue,
    blue: gains.blue - minimumGainValue
  }
  const avg = getAverageColour(colour);
  let offset = ((gains.red - colour.red + avg - 1) + (gains.green - colour.green + avg - 1) + (gains.blue - colour.blue + avg - 1)) / 3.0;
  
  colour.red = clamp(colour.red, 0, 1);
  colour.green = clamp(colour.green, 0, 1);
  colour.blue = clamp(colour.blue, 0, 1);
  offset = clamp(offset, -1, 1);

  colour = adjustColourToLightness(colour);
  colour.red = clamp(colour.red, 0, 1);
  colour.green = clamp(colour.green, 0, 1);
  colour.blue = clamp(colour.blue, 0, 1);
  return { colour, offset }
}

export const estimateColourParameters = (luts, cond) => {
  const x = Array.from({ length: 256 }, (_, i) => i / 255);
  const validIndices = x.reduce((acc, value, index) => {
    if (cond(value)) acc.push(index);
    return acc;
  }, []);
  const averages = validIndices.reduce((acc, value) => {
    const avg = (luts.red[value] +  luts.green[value] + luts.blue[value]) / 3.0;
    acc.push(avg);
    return acc;
  }, []);
  const colours = validIndices.reduce((acc, value, index) => {
    const avg = averages[index];
    acc.red.push(luts.red[value] - avg);
    acc.green.push(luts.green[value] - avg);
    acc.blue.push(luts.blue[value] - avg);
    return acc;
  }, { red: [], green: [], blue: []});
  
  const average = median(averages);
  colours.red = median(colours.red) + average;
  colours.green = median(colours.green) + average;
  colours.blue = median(colours.blue) + average;

  const minimumColour = Math.min(...Object.values(colours));
  let colour = {
    red: colours.red - minimumColour,
    green: colours.green - minimumColour,
    blue: colours.blue - minimumColour
  };

  const offsets = validIndices.reduce((acc, value) => {
    acc.push(luts.red[value] - x[value]);
    acc.push(luts.green[value] - x[value]);
    acc.push(luts.blue[value] - x[value]);
    return acc;
  }, []);

  let offset = median(offsets) / 3.0;

  colour = adjustColourToLightness(colour);
  colour.red = clamp(colour.red, 0, 1);
  colour.green = clamp(colour.green, 0, 1);
  colour.blue = clamp(colour.blue, 0, 1);
  offset = clamp(offset, -1, 1);

  return { colour, offset }; 
};

export const estimateMidtoneParameters = (luts) => {
  const x = Array.from({ length: 256 }, (_, i) => i / 255);
  const validIndices = x.reduce((acc, value, index) => {
    if (value > 1 / 3 && value < 2 / 3) acc.push(index);
    return acc;
  }, []);
  const averages = validIndices.reduce((acc, value) => {
    const avg = (luts.red[value] +  luts.green[value] + luts.blue[value]) / 3.0;
    acc.push(avg);
    return acc;
  }, []);
  const mids = validIndices.reduce((acc, value, index) => {
    const avg = averages[index];
    acc.red.push(luts.red[value] - avg);
    acc.green.push(luts.green[value] - avg);
    acc.blue.push(luts.blue[value] - avg);
    return acc;
  }, { red: [], green: [], blue: []});
  
  const average = mean(averages);
  mids.red = mean(mids.red) + average;
  mids.green = mean(mids.green) + average;
  mids.blue = mean(mids.blue) + average;

  const minimumMids = Math.min(...Object.values(mids));
  let colour = {
    red: mids.red - minimumMids,
    green: mids.green - minimumMids,
    blue: mids.blue - minimumMids
  };

  const colourAverage = getAverageColour(colour);
  let offset = ((mids.red - colour.red + colourAverage - 0.5) + (mids.green - colour.green + colourAverage - 0.5) + (mids.blue - colour.blue + colourAverage - 0.5)) / 3.0;
  
  colour.red = clamp(colour.red, 0, 1);
  colour.green = clamp(colour.green, 0, 1);
  colour.blue = clamp(colour.blue, 0, 1);
  offset = clamp(offset, -1, 1);

  colour = adjustColourToLightness(colour);
  colour.red = clamp(colour.red, 0, 1);
  colour.green = clamp(colour.green, 0, 1);
  colour.blue = clamp(colour.blue, 0, 1);

  return { colour, offset };
};

export const clamp = (val, min, max) => {
  return Math.max(Math.min(val, max), min);
}

export const getAverageColour = (colour) => {
  return (colour.red + colour.green + colour.blue) / 3.0;
}

export const estimateSaturation = (imageData) => {
  const { data, width, height } = imageData;
  const lumaWeights = [0.25, 0.50, 0.25];
  let pixelCount = 0;
  let channelSaturation = {
    red: 0,
    green: 0,
    blue: 0
  };

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
        const index = (y * width + x) * 4;
        const r = data[index] / 255.0;
        const g = data[index + 1] / 255.0;
        const b = data[index + 2] / 255.0;
        const grey = r * lumaWeights[0] + g * lumaWeights[1] + b * lumaWeights[2];

        channelSaturation.red += Math.abs(r - grey);
        channelSaturation.green += Math.abs(g - grey);
        channelSaturation.blue += Math.abs(b - grey);
        pixelCount++;
    }
  }

  const saturation = (channelSaturation.red / pixelCount + channelSaturation.green / pixelCount + channelSaturation.blue / pixelCount) / 3.0;
  return saturation;
}

export const estimateContrast = (imageData) => {
  const { data, width, height } = imageData;
  let contrast = 0;
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
        const index = (y * width + x) * 4;
        const r = data[index] / 255.0;
        const g = data[index + 1] / 255.0;
        const b = data[index + 2] / 255.0;

        contrast += Math.abs(Math.log2(r + CONTRAST_EPS) - CONTRAST_LOG_MIDPOINT);
        contrast += Math.abs(Math.log2(g + CONTRAST_EPS) - CONTRAST_LOG_MIDPOINT);
        contrast += Math.abs(Math.log2(b + CONTRAST_EPS) - CONTRAST_LOG_MIDPOINT);
    }
  }
  contrast = contrast / (height * width * 3);
  return contrast;
}

export const estimateExposure = (imageData) => {
  const { data, width, height } = imageData;
  const average = {
    red: 0.0,
    green: 0.0,
    blue: 0.0
  };
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
        const index = (y * width + x) * 4;
        const r = data[index] / 255.0;
        const g = data[index + 1] / 255.0;
        const b = data[index + 2] / 255.0;

        average.red += r;
        average.green += g;
        average.blue += b;
    }
  }
  
  const numPixels = height * width;
  average.red /= numPixels;
  average.green /= numPixels;
  average.blue /= numPixels;

  const overallIntensity = getAverageColour(average);
  const colourFilter = {
    red: average.red / overallIntensity,
    green: average.green / overallIntensity,
    blue:  average.blue / overallIntensity
  };
  const exposure = Math.log2(overallIntensity);

  return { exposure, colourFilter };
}

export const matchSaturation = (srcData, targetData) => {
  const srcSaturation = estimateSaturation(srcData);
  const targetSaturation = estimateSaturation(targetData);

  const saturation = (targetSaturation == 0.0 || srcSaturation == 0.0) ? 0.0 : clamp(targetSaturation / srcSaturation, 0, 2);

  return { saturation, targetSaturation };
};

export const matchContrast = (srcData, targetData) => {
  const srcContrast = estimateContrast(srcData);
  const targetContrast = estimateContrast(targetData);
  let contrast = targetContrast / srcContrast;
  contrast = (isFinite(contrast)) ? clamp(contrast, 0.5, 3.0) : 1.0;

  return contrast;
}

export const matchExposure = (srcData, targetData) => {
  const { exposure: srcExposure, colourFilter: srcColourFilter } = estimateExposure(srcData);
  const { exposure: targetExposure, colourFilter: targetColourFilter } = estimateExposure(targetData);
  const exposure = clamp(targetExposure - srcExposure, -3.0, 3.0);
  const colourFilter = {
    red: targetColourFilter.red / srcColourFilter.red,
    green: targetColourFilter.green / srcColourFilter.green,
    blue: targetColourFilter.blue / srcColourFilter.blue
  };

  return { exposure, colourFilter };
}

export const matchLut = (srcData, targetData) => {
  const { data: srcPixels } = srcData;
  const { data: targetPixels } = targetData;

  const srcCdf = cumulativeDistributionFunction(srcPixels);
  const targetCdf = cumulativeDistributionFunction(targetPixels);

  const luts = estimateLuts(srcCdf, targetCdf);

  return luts;
};

const clampSaturation = (colour, saturation=0.05) => {
  const hsv = new Color(colour.red, colour.green, colour.blue).toHsv();
  const newColor = Color.fromHsv(hsv.h, clamp(hsv.s, 0, saturation), 0.5);
  return {
    red: newColor.r,
    green: newColor.g,
    blue: newColor.b
  };
};

export const matchColor = (srcData, targetData) => {
  const { data: srcPixels } = srcData;
  const { data: targetPixels } = targetData;

  const srcCdf = cumulativeDistributionFunction(srcPixels);
  const targetCdf = cumulativeDistributionFunction(targetPixels);

  const luts = estimateLuts(srcCdf, targetCdf);

  const shadowMask = function(x) { return x <= 1 / 3 };
  const shadowParameters = estimateColourParameters(luts, shadowMask);

  const midtoneMask = function(x) { return x > 1 / 3 && x < 2 / 3 };
  const midtoneParameters = estimateColourParameters(luts, midtoneMask);
  midtoneParameters.colour = clampSaturation(midtoneParameters.colour, 0.05);

  const highlightMask = function(x) { return x >= 2 / 3 };
  const highlightParameters = estimateColourParameters(luts, highlightMask);

  return { shadowParameters, midtoneParameters, highlightParameters, luts };
}

export const applyLuts = (srcData, luts) => {
  const { width, height, data: srcPixels } = srcData;
  const resultImageData = new ImageData(width, height);
  const resultPixels = resultImageData.data;

  for (let i = 0; i < srcPixels.length; i += 4) {
    const colour = {
      red: clamp(luts.red[Math.round(srcPixels[i])] * 255, 0, 255),
      green: clamp(luts.green[Math.round(srcPixels[i + 1])] * 255, 0, 255),
      blue: clamp(luts.blue[Math.round(srcPixels[i + 2])] * 255, 0, 255)
    };
    resultPixels[i] = colour.red;
    resultPixels[i + 1] = colour.green;
    resultPixels[i + 2] = colour.blue;
    resultPixels[i + 3] = srcPixels[i + 3];
  }
}

export const applySaturation = (srcData, saturation) => {
  const { width, height, data: srcPixels } = srcData;
  const resultImageData = new ImageData(width, height);
  const resultPixels = resultImageData.data;

  const lumaWeights = [0.25, 0.50, 0.25];

  for (let i = 0; i < srcPixels.length; i += 4) {
    const r = srcPixels[i];
    const g = srcPixels[i + 1];
    const b = srcPixels[i + 2];
    const grey = r * lumaWeights[0] + g * lumaWeights[1] + b * lumaWeights[2];

    resultPixels[i] = Math.round(grey + saturation * (r - grey));
    resultPixels[i + 1] = Math.round(grey + saturation * (g - grey));
    resultPixels[i + 2] = Math.round(grey + saturation * (b - grey));
    resultPixels[i + 3] = srcPixels[i + 3];
  }

  return resultImageData;
}

export const applyContrast = (srcData, contrast) => {
  const { width, height, data: srcPixels } = srcData;
  const resultImageData = new ImageData(width, height);
  const resultPixels = resultImageData.data;

  for (let i = 0; i < srcPixels.length; i += 4) {
    const r = CONTRAST_LOG_MIDPOINT + (Math.log2(srcPixels[i] / 255.0 + CONTRAST_EPS) - CONTRAST_LOG_MIDPOINT) * contrast;
    const g = CONTRAST_LOG_MIDPOINT + (Math.log2(srcPixels[i + 1] / 255.0 + CONTRAST_EPS) - CONTRAST_LOG_MIDPOINT) * contrast;
    const b = CONTRAST_LOG_MIDPOINT + (Math.log2(srcPixels[i + 2] / 255.0 + CONTRAST_EPS) - CONTRAST_LOG_MIDPOINT) * contrast;
    
    resultPixels[i] = Math.round(clamp(Math.pow(2, r) - CONTRAST_EPS, 0.0, 1.0) * 255.0);
    resultPixels[i + 1] = Math.round(clamp(Math.pow(2, g) - CONTRAST_EPS, 0.0, 1.0) * 255.0);
    resultPixels[i + 2] = Math.round(clamp(Math.pow(2, b) - CONTRAST_EPS, 0.0, 1.0) * 255.0);
    resultPixels[i + 3] = srcPixels[i + 3];
  }

  return resultImageData;
}

export const estimateWhiteBalanceParameters = (srcData) => {
  const { width, height, data: srcPixels } = srcData;
  const averages = {
    red: 0.0,
    green: 0.0,
    blue: 0.0
  };
  for (let i = 0; i < srcPixels.length; i += 4) {
    const r = srcPixels[i] / 255.0;
    const g = srcPixels[i + 1] / 255.0;
    const b = srcPixels[i + 2] / 255.0;
    
    averages.red += r;
    averages.green += g;
    averages.blue += b;
  }
  const numPixels = width * height;
  averages.red /= numPixels;
  averages.green /= numPixels;
  averages.blue /= numPixels;
  const meanIntensity = getAverageColour(averages);
  const scales = {
    red: meanIntensity / averages.red,
    green: meanIntensity / averages.green,
    blue: meanIntensity / averages.blue
  };
  return { scales };
};

export const applyWhiteBalance = (srcData, params) => {
  const { width, height, data: srcPixels } = srcData;
  const resultImageData = new ImageData(width, height);
  const resultPixels = resultImageData.data;

  const { scales } = params;

  for (let i = 0; i < srcPixels.length; i += 4) {
    resultPixels[i] = clamp(Math.round(srcPixels[i] * scales.red), 0, 255);
    resultPixels[i + 1] = clamp(Math.round(srcPixels[i + 1] * scales.green), 0, 255);
    resultPixels[i + 2] = clamp(Math.round(srcPixels[i + 2] * scales.blue), 0, 255);
    resultPixels[i + 3] = srcPixels[i + 3];
  }

  return resultImageData;
}


export const applyExposure = (srcData, exposure, colourFilter={red: 1.0, green: 1.0, blue: 1.0}) => {
  const { width, height, data: srcPixels } = srcData;
  const resultImageData = new ImageData(width, height);
  const resultPixels = resultImageData.data;

  for (let i = 0; i < srcPixels.length; i += 4) {
    const r = clamp(srcPixels[i] / 255.0 * Math.pow(2.0, exposure) * colourFilter.red, 0.0, 1.0);
    const g = clamp(srcPixels[i + 1] / 255.0 * Math.pow(2.0, exposure) * colourFilter.green, 0.0, 1.0);
    const b = clamp(srcPixels[i + 2] / 255.0 * Math.pow(2.0, exposure) * colourFilter.blue, 0.0, 1.0);
    
    resultPixels[i] = Math.round(r * 255.0);
    resultPixels[i + 1] = Math.round(g * 255.0);
    resultPixels[i + 2] = Math.round(b * 255.0);
    resultPixels[i + 3] = srcPixels[i + 3];
  }

  return resultImageData;
}

export const applyColor = (srcData, shadowParameters, midtoneParameters, highlightParameters) => {
  const { width, height, data: srcPixels } = srcData;
  const resultImageData = new ImageData(width, height);
  const resultPixels = resultImageData.data;  

  const shadowColourAverage = getAverageColour(shadowParameters.colour);
  const midtoneColourAverage = getAverageColour(midtoneParameters.colour);
  const highlightColourAverage = getAverageColour(highlightParameters.colour);
  const lifts = {
    red: shadowParameters.colour.red - shadowColourAverage,
    green: shadowParameters.colour.green - shadowColourAverage,
    blue: shadowParameters.colour.blue - shadowColourAverage
  }
  const gammas = {
    red: midtoneParameters.colour.red - midtoneColourAverage,
    green: midtoneParameters.colour.green -  midtoneColourAverage,
    blue: midtoneParameters.colour.blue - midtoneColourAverage
  }
  const gains = {
    red: highlightParameters.colour.red - highlightColourAverage,
    green: highlightParameters.colour.green - highlightColourAverage,
    blue: highlightParameters.colour.blue - highlightColourAverage
  }

  const liftAdjust  = {
    red: lifts.red + shadowParameters.offset,
    green: lifts.green + shadowParameters.offset,
    blue: lifts.blue + shadowParameters.offset
  }
  const gainAdjust = {
    red: 1 + gains.red + highlightParameters.offset,
    green: 1 + gains.green + highlightParameters.offset,
    blue: 1 + gains.blue + highlightParameters.offset
  }
  const mids = {
    red: 0.5 + gammas.red + midtoneParameters.offset,
    green: 0.5 + gammas.green + midtoneParameters.offset,
    blue: 0.5 + gammas.blue + midtoneParameters.offset
  }
  const gammaAdjust = {
    red: Math.log((0.5 - liftAdjust.red) / (gainAdjust.red - liftAdjust.red)) / Math.log(mids.red),
    green: Math.log((0.5 - liftAdjust.green) / (gainAdjust.green - liftAdjust.green)) / Math.log(mids.green),
    blue: Math.log((0.5 - liftAdjust.blue) / (gainAdjust.blue - liftAdjust.blue)) / Math.log(mids.blue)
  }
  const inverseGammaAdjust = {
    red: 1.0 / clamp(gammaAdjust.red, 0.1, 2.0),
    green: 1.0 / clamp(gammaAdjust.green, 0.1, 2.0),
    blue: 1.0 / clamp(gammaAdjust.blue, 0.1, 2.0)
  }

  for (let i = 0; i < srcPixels.length; i += 4) {
    const lerps = {
      red: Math.pow(srcPixels[i] / 255.0, inverseGammaAdjust.red),
      green: Math.pow(srcPixels[i + 1] / 255.0, inverseGammaAdjust.green),
      blue: Math.pow(srcPixels[i + 2] / 255.0, inverseGammaAdjust.blue)
    };
    const colour = {
      red: clamp((liftAdjust.red + lerps.red * (gainAdjust.red - liftAdjust.red)) * 255, 0, 255),
      green: clamp((liftAdjust.green + lerps.green * (gainAdjust.green - liftAdjust.green)) * 255, 0, 255),
      blue: clamp((liftAdjust.blue + lerps.blue * (gainAdjust.blue - liftAdjust.blue)) * 255, 0, 255),
    }
    resultPixels[i] = colour.red;
    resultPixels[i + 1] = colour.green;
    resultPixels[i + 2] = colour.blue;
    resultPixels[i + 3] = srcPixels[i + 3];   // Alpha channel (unchanged)
  }

  return resultImageData;
}
