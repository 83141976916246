import React, { useContext, useState } from 'react';
import Canvas from './mobile/Canvas';
import { DEFAULT_ZOOM } from './matchUtils';
import FloatingTopPanel from './FloatingTopPanel';
import DragAndDrop from './DragAndDrop';
import FloatingBottomPanel from './FloatingBottomPanel';
import { AppContext } from '../AppContext';

export const Display = ({
  imgData,
  sourceImage,
  params,
  history,
  undo,
  redo,
  isDisabled,
  handleSourceImageUpload,
  onSourceImageChange,
  onRemoveImage
}) => {
  const [isHoverOnDisplay, setIsHoverOnDisplay] = useState(false);
  const { error, setError } = useContext(AppContext);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);  
  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  return (
    <div
    style={{
      textAlign: "center",
      width: '70%',
      height: '100%',
      position: "relative",
      overflow: "visible",
    }}
    onMouseEnter={() => setIsHoverOnDisplay(true)}
    onMouseLeave={() => setIsHoverOnDisplay(false)}
  > 
    <FloatingTopPanel
      history={history}
      isImageLoaded={imgData != null}
      undo={undo}
      redo={redo}
      isDisabled={isDisabled}
      error={error}
      sourceImage={sourceImage}
      params={params}
    />

    {/* Drag-and-Drop Area */}
    <DragAndDrop 
      imgData={imgData}
      onChange={handleSourceImageUpload}
      onSourceImageChange={onSourceImageChange}
      onError={(msg) => {
        setError(msg);
        setTimeout(() => setError(null), 5000);
      }}
    />
    {/* <CanvasDisplay imgData={imgData} zoom={zoom} params={params} dimensions={{canvasWidth, canvasHeight}}/> */}
    <Canvas imgData={imgData} params={params} zoom={zoom}/>
    {/* Zoom Slider */}
    <div
      style={{
        position: "absolute", // Makes this hover over the display
        bottom: isHoverOnDisplay && imgData ? "10px" : '-50px', // Adjust distance from the bottom of the container
        left: "50%", // Center horizontally within the container
        transform: "translateX(-50%)", // Adjust for centering
        backgroundColor: isHoverOnDisplay && imgData ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.0)",
        padding: "5px 20px", // Optional: Add padding
        borderRadius: "5px", // Optional: Rounded corners for styling
        zIndex: 10, // Ensure it hovers on top of other content
        transition: 'all 1s ease'
      }}
    >
      {imgData
        &&
        <FloatingBottomPanel
          zoom={zoom}
          onChange={handleZoomChange}
          onRemoveImage={onRemoveImage}
          isDisabled={isDisabled}
        />
      }
    </div>
  </div>
  );
};

export default Display;
