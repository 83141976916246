/* eslint-disable react/prop-types */
import React from 'react';
import { COLOUR_200, COLOUR_700 } from './utils';

const Spinner = ({ size = 30, color = COLOUR_200, backgroundColor = COLOUR_700, thickness = 3 }) => {
  const spinnerStyles = {
    display: 'inline-block',
    width: size,
    height: size,
    border: `${thickness}px solid ${backgroundColor}`, // Light background ring
    borderTop: `${thickness}px solid ${color}`, // Colored top ring
    borderRadius: '50%',
    animation: 'spin 1s linear infinite', // Rotating animation
  };

  return (
    <div>
      <div style={spinnerStyles}></div>
      <style>
        {`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  );
};

export default Spinner;
