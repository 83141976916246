import { Helmet } from "react-helmet";

const TermsAndConditions = ({isMobile}) => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Reka-Reka</title>
        <meta 
          name="description" 
          content="Review the Terms and Conditions for using Reka-Reka, including usage policies, disclaimers, and user responsibilities." 
        />
        <meta 
          name="keywords" 
          content="terms and conditions, user agreement, service terms, Reka-Reka policies, website terms" 
        />
        <link rel="canonical" href="https://reka-reka.com/terms" />
        <meta property="og:title" content="Terms and Conditions | Reka-Reka" />
        <meta property="og:description" content="Read the terms of service for using Reka-Reka, including liability, permitted use, and Buy Me a Coffee transactions." />
        <meta property="og:image" content="https://reka-reka.com/logo.png" />
        <meta property="og:url" content="https://reka-reka.com/terms" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Terms and Conditions | Reka-Reka" />
        <meta name="twitter:description" content="Understand your rights and responsibilities when using Reka-Reka’s services and features." />
        <meta name="twitter:image" content="https://reka-reka.com/logo.png" />
        <meta name="robots" content="index, follow" />
        <link rel="icon" href="https://reka-reka.com/favicon.ico" sizes="any" />
        <link rel="apple-touch-icon" href="https://reka-reka.com/logo.png" />
      </Helmet>


      <main className="how-it-works">
        <div>
          <h1 className="title">Terms & Conditions</h1>
          <div style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">1. Acceptance of Terms</h2>
            <p className="paragraph">By accessing and using this website, you agree to be bound by these Terms and Conditions. If you do not agree, please do not use the website.</p>
          </div>

          <section style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">2. Use of the Website</h2>
            <ul className="paragraph">
              <li>This website allows users to upload images for editing and download the processed images.</li>
              <li>Users must not upload copyrighted, illegal, or inappropriate content.</li>
              <li>We do not store uploaded images; all processing occurs locally in the browser or on temporary servers.</li>
            </ul>
          </section>

          <section style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">3. Limitation of Liability</h2>
            <ul className="paragraph">
              <li>We are not responsible for any loss, corruption, or misuse of images uploaded to the platform.</li>
              <li>We do not guarantee the availability, accuracy, or uninterrupted operation of the website.</li>
              <li>Users assume full responsibility for any issues arising from the use of this website.</li>
            </ul>
          </section>

          <section style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">4. Third-Party Services</h2>
            <ul className="paragraph">
              <li>We use Google Analytics to collect usage data (e.g., device type, browser, and interactions) to improve the website.</li>
              <li>"Buy Me a Coffee" is a third-party service that enables users to make voluntary contributions. We are not responsible for any issues related to transactions on this platform.</li>
            </ul>
          </section>

          <section style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">5. Intellectual Property</h2>
            <ul className="paragraph">
              <li>The website and its features are our intellectual property and cannot be copied or redistributed without permission.</li>
              <li>Any filters, presets, or tools provided on this platform are for personal use only.</li>
            </ul>
          </section>


          {
            /* <h2>6. Contact Us</h2>
            <p>If you have any questions about this policy, you can reach out to us at <strong>[Your Email Address]</strong>.</p> */
          }
          
          <section style={{paddingTop: isMobile ? 30 : 70}}>
            <h2 className="subtitle">6. Changes to Terms</h2>
            <p className="paragraph">We may update these Terms at any time. Continued use of the website after updates constitutes acceptance of the new terms.</p>
          </section>
        </div>
      </main>
    </>
  );
};

export default TermsAndConditions;
