/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { COLOUR_10, COLOUR_400, COLOUR_BLUE } from './utils';
import DownloadButton from './DownloadButton';

const FloatingTopPanel = ({isImageLoaded, history, undo, redo, isDisabled, error, sourceImage, params }) => {
  const [hoverOnUndo, setHoverOnUndo] = useState(false);
  const [hoverOnRedo, setHoverOnRedo] = useState(false);

  return (
    <div style={{position: 'absolute', display: 'flex', justifyContent: 'end', marginTop: 10, gap: 5, width: '100%'}}>

      {
        <div
          style={{
            fontSize: '14px',
            fontWeight: 500,
            color: error ? COLOUR_10 : undefined,
            backgroundColor: 'rgba(255, 132, 185, 0.5)',
            padding: error ? '15px 30px' : '0px 0px',
            border: '1px solid rgb(255, 0, 0)',
            borderRadius: error ? '5px' : '5rem',
            transition: 'all 0.5s ease',
            opacity: error ? 1.0 : 0.0,
            marginRight: 10
          }}
        >
          {error}
        </div>
      }

      {
        isImageLoaded && <DownloadButton isDisabled={isDisabled}/>
      }

      {isImageLoaded && <button 
        onMouseEnter={() => setHoverOnUndo(true)}
        onMouseLeave={() => setHoverOnUndo(false)} 
        style={{
          zIndex: 3,
          fontSize: '16px',
          opacity: history.past.length > 0 && !isDisabled ? 0.5 : 0.2,
          border: 'none',
          outline: 'none',
          marginRight: 10,
          backgroundColor: 'transparent',
          cursor: history.past.length > 0 && !isDisabled ? 'pointer' : undefined,
          transition: 'all 0.5s ease'
        }}
        onClick={history.past.length > 0 ? undo : undefined}
        disabled={isDisabled}
      >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke={hoverOnUndo && history.past.length > 0 ? COLOUR_BLUE : COLOUR_400}
        strokeWidth="2"
        style={{
          width: '24px',
          height: '24px',
          color: '#555',
        }}
      >
        <path strokeLinecap="round" strokeLinejoin="round"
          d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 13h-2"></path>        
      </svg>
      </button>
      }
      {isImageLoaded &&
        <button 
          onMouseEnter={() => setHoverOnRedo(true)}
          onMouseLeave={() => setHoverOnRedo(false)} 
          style={{
            zIndex: 3,
            fontSize: '16px',
            opacity: history.future.length > 0 && !isDisabled ? 0.5 : 0.2,
            border: 'none',
            outline: 'none',
            marginRight: 10,
            backgroundColor: 'transparent',
            cursor: history.future.length > 0 && !isDisabled ? 'pointer' : undefined,
            transition: 'all 0.5s ease'
          }}
          onClick={history.future.length > 0 ? redo : undefined}
          disabled={isDisabled}
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke={hoverOnRedo && history.future.length > 0 ? COLOUR_BLUE : COLOUR_400}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{
            width: '24px',
            height: '24px',
            color: '#555',
          }}
        >  
          <path strokeLinecap="round" strokeLinejoin="round"
          d="m15 15 6-6m0 0-6-6m6 6H9a6 6 0 0 0 0 12h2"></path> 
        </svg>
        </button>
      }
    </div>
  );
};

export default FloatingTopPanel;
