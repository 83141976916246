export const COLOUR_10 = 'rgb(242, 242, 247)';
export const COLOUR_50 = 'rgb(229, 229, 234)';
export const COLOUR_100 = 'rgb(209, 209, 214)';
export const COLOUR_200 = 'rgb(199, 199, 204)';
export const COLOUR_300 = 'rgb(174, 174, 178)';
export const COLOUR_400 = 'rgb(142, 142, 147)';
export const COLOUR_500 = 'rgb(99, 99, 102)';
export const COLOUR_600 = 'rgb(72, 72, 74)';
export const COLOUR_700 = 'rgb(58, 58, 60)';
export const COLOUR_800 = 'rgb(44, 44, 46)';
export const COLOUR_825 = 'rgb(40, 40, 42)';
export const COLOUR_850 = 'rgb(36, 36, 38)';
export const COLOUR_875 = 'rgb(30, 30, 32)';
export const COLOUR_900 = 'rgb(28, 28, 30)';
export const COLOUR_950 = 'rgb(22, 22, 24)';
export const COLOUR_1000 = 'rgb(10, 10, 12)';

export const COLOUR_BLUE = '#AAAAFF';
export const COLOUR_BLUE_OPACITY = 'rgba(200, 200, 255, 0.2)';
export const COLOUR_RED = '#FF1111';
export const COLOUR_RED_DOWNLOAD = 'rgb(238, 85, 85)';

const compareColourParameters = (colourParameter, otherColourParameter) => {
  return (
    colourParameter.colour.red == otherColourParameter.colour.red
    &&
    colourParameter.colour.green == otherColourParameter.colour.green
    &&
    colourParameter.colour.blue == otherColourParameter.colour.blue
    &&
    colourParameter.offset  == otherColourParameter.offset
  );
}

export const compareParams = (params, otherParams) => {
  const isSame = (
    params.saturation == otherParams.saturation
    &&
    params.contrast == otherParams.contrast
    &&
    params.exposure == otherParams.exposure
    &&
    params.filter?.name == otherParams.filter?.name
    &&
    compareColourParameters(params.shadowParameters, otherParams.shadowParameters)
    &&
    compareColourParameters(params.midtoneParameters, otherParams.midtoneParameters)
    &&
    compareColourParameters(params.highlightParameters, otherParams.highlightParameters)
  );
  return isSame;
}

export const timeToString = (time) => {
  const minute = String(Math.floor(time / 60.0).toFixed(0)).padStart(2, '0');
  const second = String(Math.floor(time % 60.0).toFixed(0)).padStart(2, '0');
  const millisecond = String(Math.min(999, (time % 1 * 1000)).toFixed(0)).padStart(3, '0');
  return `00:${minute}:${second}.${millisecond}`;
};

export const linear = (val, minIn, maxIn, minOut, maxOut) => (maxOut - minOut) / (maxIn - minIn) * (val - minIn) + minOut;

export const clamp = (val, minVal, maxVal) => Math.min(Math.max(minVal, val), maxVal);

export const distanceFromRect = (x, y, rect) => {
  return [rect.x - x, rect.x + rect.width - x, rect.y - y, rect.y + rect.height - y];
}

export const posInRect = (x, y, rect) => {
  return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
}

export const rectIntersectRect = (rect1, rect2) => {
  return posInRect(rect1.x, rect1.y, rect2) || posInRect(rect1.x + rect1.width, rect1.y, rect2) || posInRect(rect1.x, rect1.y + rect1.height, rect2) || posInRect(rect1.x + rect1.width, rect1.y + rect1.height, rect2);
}

export const areSequencesOverlapping = (sequenceList) => {
  const startTimes = sequenceList.map((seq) => seq.activeAt.startTime);
  const endTimes = sequenceList.map((seq) => seq.activeAt.endTime);
  return Math.max(...startTimes) <= Math.min(...endTimes);
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const calcDistance = (points1, points2) => {
  return Math.sqrt(Math.pow(points1.y - points2.y, 2.0) + Math.pow(points1.x - points2.x, 2.0));
}

export const calcAngle = (points, center) => {
  return (Math.atan2(points.y - center.y, points.x - center.x) + 2 * Math.PI) % (2 * Math.PI);
}

export const calcPoints = (distance, angle) => {
  return {x: distance * Math.cos(angle), y: distance * Math.sin(angle)};
}

export const degreesToRadians = (degrees) => {
  return degrees * Math.PI / 180;
}

export class Color {
  constructor(r, g, b, a) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }

  // h [0, 360]
  // s [0, 1]
  // v [0, 1]  
  static fromHsv(hue, saturation, value) {
    const h = hue % 360;
    const chroma = value * saturation;
    const X = chroma * (1 - Math.abs((h / 60.0) % 2 - 1));
    if (h >= 0 && h < 60) {
      return new Color(chroma, X, 0);
    } else if (h >= 60 && h < 120) {
      return new Color(X, chroma, 0);
    } else if (h >= 120 && h < 180) {
      return new Color(0, chroma, X);
    } else if (h >= 180 && h < 240) {
      return new Color(0, X, chroma);
    } else if (h >= 240 && h < 300) {
      return new Color(X, 0, chroma);
    } else {
      return new Color(chroma, 0, X);
    }
  }

  // h [0, 360]
  // s [0, 1]
  // l [0, 1]
  static fromHsl(hue, saturation, lightness) {
    const h = hue % 360;
    const chroma = (1 - Math.abs(2 * lightness - 1)) * saturation;
    const X = chroma * (1 - Math.abs((h / 60.0) % 2 - 1));

    let color;
    if (h >= 0 && h < 60) {
      color = new Color(chroma, X, 0);
    } else if (h >= 60 && h < 120) {
      color = new Color(X, chroma, 0);
    } else if (h >= 120 && h < 180) {
      color = new Color(0, chroma, X);
    } else if (h >= 180 && h < 240) {
      color = new Color(0, X, chroma);
    } else if (h >= 240 && h < 300) {
      color = new Color(X, 0, chroma);
    } else {
      color = new Color(chroma, 0, X);
    }

    const m = lightness - (chroma / 2);

    color.r = clamp(color.r + m, 0, 1);
    color.g = clamp(color.g + m, 0, 1);
    color.b = clamp(color.b + m, 0, 1);

    return color;
  }

  static fromYuv(y, u, v) {
    return new Color(
      clamp(y + v * 1.5748, 0, 1),
      clamp(y + u * -0.1873 + v * -0.4681, 0, 1),
      clamp(y + u * 1.8556, 0, 1)
    );
  }

  toString() {
    return `rgb(${this.r * 255}, ${this.g * 255}, ${this.b * 255})`;
  }

  toArray() {
    return [this.r, this.g, this.b];
  }

  toUint8ClampedArray() {
    return new Uint8ClampedArray([
      clamp(Math.round(this.r * 255), 0, 255),
      clamp(Math.round(this.g * 255), 0, 255),
      clamp(Math.round(this.b * 255), 0, 255)
    ]);
  }

  toYuv() {
    return {
      y: 0.2126 * this.r + 0.7152 * this.g + 0.0722 * this.b,
      u: -0.1146 * this.r + -0.3854 * this.g + 0.5 * this.b,
      v: 0.5 * this.r + -0.4542 * this.g + -0.0458 * this.b
    };
  }

  toHsv() {
    const max = Math.max(this.r, this.g, this.b);
    const min = Math.min(this.r, this.g, this.b);
    const chroma = max - min;
  
    // Calculate Hue
    let hue;
    if (chroma === 0) {
      hue = 0; // Undefined hue for greyscale colors
    } else if (max === this.r) {
      hue = 60 * (((this.g - this.b) / chroma) % 6);
    } else if (max === this.g) {
      hue = 60 * (((this.b - this.r) / chroma) + 2);
    } else {
      hue = 60 * (((this.r - this.g) / chroma) + 4);
    }
    if (hue < 0) hue += 360; // Ensure hue is positive
    
    // Calculate Saturation (Not sure if this is right but it works)
    const saturation = max === 0 ? 0 : chroma * 2;
  
    // Calculate Value
    const value = saturation == 0.0 ? 0.0 : chroma / saturation;
  
    return {
      h: hue,
      s: clamp(saturation, 0, 1),
      v: value
    };
  }  

  toHsl() {
    const maxComponent = Math.max(this.r, this.g, this.b);
    const minComponent = Math.min(this.r, this.g, this.b);
    const chroma = maxComponent - minComponent;

    const lightness = (maxComponent + minComponent) / 2.0;

    let hue;
    if (chroma == 0) {
      hue = 0;
    } else if (maxComponent == this.r) {
      hue = 60 * ((this.g - this.b) / chroma % 6);
    } else if (maxComponent == this.g) {
      hue = 60 * ((this.b - this.r) / chroma + 2);
    } else {
      hue = 60 * ((this.r - this.g) / chroma + 4);
    }

    const saturation = (lightness == 0 || lightness == 1) ? 0 : (maxComponent - lightness) / Math.min(lightness, 1 - lightness);

    return {
      h: hue,
      s: saturation,
      l: lightness
    };
  }

  static MIXIN = {
    // eslint-disable-next-line no-unused-vars
    NORMAL: (topColor, _bottomColor) => { return topColor },
    LIGHTEN: (topColor, bottomColor) => { return Math.max(topColor, bottomColor) },
    DARKEN: (topColor, bottomColor) => { return Math.min(topColor, bottomColor) },
    MULTIPLY: (topColor, bottomColor) => { return topColor * bottomColor },
    SCREEN: (topColor, bottomColor) => {
      return bottomColor + topColor - (bottomColor * topColor);
    },
    COLOR_DODGE: (topColor, bottomColor) => {
      if (bottomColor == 0) {
        return 0;
      } else if (topColor == 1) {
        return 1;
      } else {
        return Math.min(1, bottomColor / (1 - topColor));
      }
    },
    COLOR_BURN: (topColor, bottomColor) => {
      if (bottomColor == 1) {
        return 1;
      } else if (topColor == 0) {
        return 0;
      } else {
        return 1 - Math.min(1, (1 - bottomColor) / topColor);
      }
    },
    HARD_LIGHT: (topColor, bottomColor) => {
      if (topColor <= 0.5) {
        return Color.MIXIN.MULTIPLY(bottomColor, 2 * topColor);
      } else {
        return Color.MIXIN.SCREEN(bottomColor, 2 * topColor - 1);
      }
    },
    OVERLAY: (topColor, bottomColor) => {
      return Color.MIXIN.HARD_LIGHT(bottomColor, topColor);
    },
    SOFT_LIGHT: (topColor, bottomColor) => {
      return (1 - 2 * bottomColor) * topColor * topColor + 2 * bottomColor * topColor;
    },
    DIFFERENCE: (topColor, bottomColor) => {
      return Math.abs(topColor - bottomColor);
    },
    EXCLUSION: (topColor, bottomColor) => {
      return bottomColor + topColor - 2 * bottomColor * topColor;
    },
    LINEAR_DODGE: (topColor, bottomColor) => {
      return Math.min(topColor + bottomColor, 1);
    },
    LINEAR_BURN: (topColor, bottomColor) => {
      return Math.max(topColor + bottomColor - 1, 0);
    },
    LINEAR_LIGHT: (topColor, bottomColor) => {
      return (topColor < 0.5) ? 
        Color.MIXIN.LINEAR_BURN(topColor * 2, bottomColor) :
        Color.MIXIN.LINEAR_DODGE(2 * (topColor - 0.5), bottomColor);
    },
    VIVID_LIGHT: (topColor, bottomColor) => {
      return (topColor < 0.5) ? 
        Color.MIXIN.COLOR_BURN(topColor * 2, bottomColor) :
        Color.MIXIN.COLOR_DODGE(2 * (topColor - 0.5), bottomColor);
    },
    PIN_LIGHT: (topColor, bottomColor) => {
      return (topColor < 0.5) ? 
        Color.MIXIN.DARKEN(topColor * 2, bottomColor) :
        Color.MIXIN.LIGHTEN(2 * (topColor - 0.5), bottomColor);
    },
    HARD_MIX: (topColor, bottomColor) => {
      return (Color.MIXIN.VIVID_LIGHT(topColor, bottomColor) < 0.5) ? 0.0 : 1.0;
    },
    REFLECT: (topColor, bottomColor) => {
      return (topColor == 1.0) ? topColor : Math.min(bottomColor * bottomColor / (1 - topColor), 1);
    },
    GLOW: (topColor, bottomColor) => {
      return Color.MIXIN.REFLECT(bottomColor, topColor);
    },
    PHEONIX: (topColor, bottomColor) => {
      return Math.min(topColor, bottomColor) - Math.max(topColor, bottomColor) + 1;
    },
    HUE: (topColor, bottomColor) => {
      const topColorHsl = topColor.toHsl();
      const bottomColorHsl = bottomColor.toHsl();
      return Color.fromHsl(
        topColorHsl.h,
        bottomColorHsl.s,
        bottomColorHsl.l
      );
    },
    SATURATION: (topColor, bottomColor) => {
      const topColorHsl = topColor.toHsl();
      const bottomColorHsl = bottomColor.toHsl();
      return Color.fromHsl(
        bottomColorHsl.h,
        topColorHsl.s,
        bottomColorHsl.l
      );
    },
    COLOR: (topColor, bottomColor) => {
      const topColorHsl = topColor.toHsl();
      const bottomColorHsl = bottomColor.toHsl();
      return Color.fromHsl(
        topColorHsl.h,
        topColorHsl.s,
        bottomColorHsl.l
      );
    },
    LUMINOSITY: (topColor, bottomColor) => {
      const topColorHsl = topColor.toHsl();
      const bottomColorHsl = bottomColor.toHsl();
      return Color.fromHsl(
        bottomColorHsl.h,
        bottomColorHsl.s,
        topColorHsl.l
      );
    }
  }

  static blend(topColor, bottomColor, mode) {
    // https://www.w3.org/TR/compositing-1/
    let mixin;
    switch (mode) {
      case 'normal':
        mixin = Color.MIXIN.NORMAL;
        break;
      case 'lighten':
        mixin = Color.MIXIN.LIGHTEN;
        break;
      case 'darken':
        mixin = Color.MIXIN.DARKEN;
        break;
      case 'overlay':
        mixin = Color.MIXIN.OVERLAY;
        break;
      case 'multiply':
        mixin = Color.MIXIN.MULTIPLY;
        break;
      case 'screen':
        mixin = Color.MIXIN.SCREEN;
        break;
      case 'color dodge':
        mixin = Color.MIXIN.COLOR_DODGE;
        break;
      case 'color burn':
        mixin = Color.MIXIN.COLOR_BURN;
        break;
      case 'hard light':
        mixin = Color.MIXIN.HARD_LIGHT;
        break;
      case 'soft light':
        mixin = Color.MIXIN.SOFT_LIGHT;
        break;
      case 'difference':
        mixin = Color.MIXIN.DIFFERENCE;
        break;
      case 'exclusion':
        mixin = Color.MIXIN.EXCLUSION;
        break;
      case 'hue':
        mixin = Color.MIXIN.HUE;
        break;
      case 'saturation':
        mixin = Color.MIXIN.SATURATION;
        break;
      case 'luminosity':
        mixin = Color.MIXIN.LUMINOSITY;
        break;
      case 'color':
        mixin = Color.MIXIN.COLOR;
        break;
      case 'linear dodge':
        mixin = Color.MIXIN.LINEAR_DODGE;
        break;
      case 'linear burn':
        mixin = Color.MIXIN.LINEAR_BURN;
        break;
      case 'linear light':
        mixin = Color.MIXIN.LINEAR_LIGHT;
        break;
      case 'vivid light':
        mixin = Color.MIXIN.VIVID_LIGHT;
        break;
      case 'pin light':
        mixin = Color.MIXIN.PIN_LIGHT;
        break;
      case 'hard mix':
        mixin = Color.MIXIN.HARD_MIX;
        break;
      case 'reflect':
        mixin = Color.MIXIN.REFLECT;
        break;
      case 'glow':
        mixin = Color.MIXIN.GLOW;
        break;
      case 'pheonix':
        mixin = Color.MIXIN.PHEONIX;
        break;
      default:
        throw new Error(`Blend mode ${mode} does not exist`);
    }

    const bottomColorAlpha = bottomColor.a || 1;
    const topColorAlpha = topColor.a || 1;

    let srcColor;
    const nonSeparables = ['hue', 'saturation', 'color', 'luminosity'];
    if (!nonSeparables.includes(mode)) {
      srcColor = new Color(
        (1 - bottomColorAlpha) * topColor.r + bottomColorAlpha * mixin(topColor.r, bottomColor.r),
        (1 - bottomColorAlpha) * topColor.g + bottomColorAlpha * mixin(topColor.g, bottomColor.g),
        (1 - bottomColorAlpha) * topColor.b + bottomColorAlpha * mixin(topColor.b, bottomColor.b),
      );      
    } else {
      const mixed = mixin(topColor, bottomColor);
      srcColor = new Color(
        (1 - bottomColorAlpha) * topColor.r + bottomColorAlpha * mixed.r,
        (1 - bottomColorAlpha) * topColor.g + bottomColorAlpha * mixed.g,
        (1 - bottomColorAlpha) * topColor.b + bottomColorAlpha * mixed.b,
      );
    }

    // source over
    // Fa = 1; Fb = 1 – αs
    // co = αs x Cs + αb x Cb x (1 – αs)
    // αo = αs + αb x (1 – αs)
    return new Color(
      srcColor.r * topColorAlpha + bottomColorAlpha * bottomColor.r * (1 - topColorAlpha),
      srcColor.g * topColorAlpha + bottomColorAlpha * bottomColor.g * (1 - topColorAlpha),
      srcColor.b * topColorAlpha + bottomColorAlpha * bottomColor.b * (1 - topColorAlpha)
    );
  }
}
