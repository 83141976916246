/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { calcDistance, calcPoints, calcAngle, linear, Color, capitalize, clamp, COLOUR_10, COLOUR_800, COLOUR_950, COLOUR_825, COLOUR_400, COLOUR_700, COLOUR_900, posInRect } from "../utils";
import { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { DEFAULT_COLOUR_PARAMETERS } from "../matchUtils";

const HueRing = ({pickerPosition = { x: 50, y: 50 }, hoverOnPicker, onMouseMove, onMouseUp, onMouseLeave, onMouseEnterOnPicker, onMouseLeaveOnPicker, onMouseDownOnPicker, onTouchStartOnPicker, onTouchEnd, onTouchMove, isDisabled}) => {
  const thickness = 5;
  const outerRadius = 50; // Outer radius of the circle
  const innerRadius = outerRadius - thickness; // Inner radius based on thickness
  const outerArcLength = outerRadius * 2; // Adjust outer arc length
  const innerArcLength = innerRadius * 2; // Adjust inner arc length
  const pickerSize = 20;
  const strokeWidth = hoverOnPicker ? 1 : 2;

  const pickerAreaRef = useRef(null);

  const handleMouseMove = (e) => {
    e.preventDefault();
    const isInsidePicker = posInRect(e.clientX, e.clientY, pickerAreaRef.current.getBoundingClientRect());
    if (isInsidePicker) {
      onMouseEnterOnPicker();
    } else {
      onMouseLeaveOnPicker();
    }
    const { width, height, x, y } = e.target.getBoundingClientRect();
    const offsetX = linear(e.clientX, x, x + width, -1.0, 1.0);
    const offsetY = linear(e.clientY, y, y + height, -1.0, 1.0);
    onMouseMove({x: offsetX, y: offsetY});
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    const { width, height, x, y } = e.target.getBoundingClientRect();
    const offsetX = linear(touch.clientX, x, x + width, -1.0, 1.0);
    const offsetY = linear(touch.clientY, y, y + height, -1.0, 1.0);
    onTouchMove({x: offsetX, y: offsetY});
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    const isInsidePicker = posInRect(touch.clientX, touch.clientY, pickerAreaRef.current.getBoundingClientRect());
    if (isInsidePicker) {
      onTouchStartOnPicker();
    }
  }

  const handleTouchEnd = (e) => {
    e.preventDefault();
    onTouchEnd(e);
  }

  const handleMouseDown = (e) => {
    e.preventDefault();
    const isInsidePicker = posInRect(e.clientX, e.clientY, pickerAreaRef.current.getBoundingClientRect());
    if (isInsidePicker) {
      onMouseDownOnPicker();
    }
  };

  return (
    <div style={{position: 'relative', width: '100%', height: '100%'}}>
      <svg
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        style={{padding: '10%', width: '100%', height: '100%', cursor: hoverOnPicker ? 'pointer' : undefined }}
      >
        <defs>
          <clipPath id="clip">
          <path d={`
            M 50 0
            a ${outerRadius} ${outerRadius} 0 0 1 0 ${outerArcLength}
            ${outerRadius} ${outerRadius} 0 0 1 0 -${outerArcLength}
            v ${thickness}
            a ${innerRadius} ${innerRadius} 0 0 0 0 ${innerArcLength}
            ${innerRadius} ${innerRadius} 0 0 0 0 -${innerArcLength}
          `} />
          </clipPath>
        </defs>
        <foreignObject x="0" y="0" width="100" height="100" clipPath="url(#clip)">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              backgroundImage: 'conic-gradient' + '(' +
                '' +
                '#ff0000 0deg' +
                ',' +
                `#ff00ff 60deg` +
                ',' +
                `#0000ff 120deg` +
                ',' +
                '#00ffff 180deg' +
                ',' +
                `#00ff00 240deg` +
                ',' +
                `#ffff00 300deg` +
                ',' +
                '#ff0000 360deg' +
                ')',
            }}
          />
        </foreignObject>
        <line
          x1={pickerPosition.x - pickerSize / 2}
          y1={pickerPosition.y}
          x2={pickerPosition.x + pickerSize / 2}
          y2={pickerPosition.y}
          stroke={isDisabled ? COLOUR_800 : COLOUR_10}
          strokeWidth={strokeWidth}
        />
        <line
          x1={pickerPosition.x}
          y1={pickerPosition.y - pickerSize / 2}
          x2={pickerPosition.x}
          y2={pickerPosition.y + pickerSize / 2}
          stroke={isDisabled ? COLOUR_800 : COLOUR_10}
          strokeWidth={strokeWidth}

        />
        {/*Invisible circles for simplify interaction mechanism */}
        <circle
          ref={pickerAreaRef}
          cx={pickerPosition.x}
          cy={pickerPosition.y}
          r={pickerSize / 2}
          opacity={0.0}
          strokeWidth={strokeWidth}
        />
        <circle
          cx={50}
          cy={50}
          r={innerRadius}
          opacity={0.0}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
        />
        {
          isDisabled
          &&
          <circle
            cx={50}
            cy={50}
            r={innerRadius}
            opacity={0.0}
          />
        }

      </svg>
      
    </div>
  );
};

// eslint-disable-next-line react/prop-types
export const ColorWheelPickerComponent = ({ name, colour, offset, onParameterChange, onColourChange, onOffsetChange, updateHistory, isDisabled }) => {
  const ref = useRef(null);
  const thumbColour = COLOUR_950;
  const thumbBorder = `2px solid ${COLOUR_800}`;

  const minOffset = -1.0;
  const maxOffset = 1.0;
  const defaultOffset = 0.0;

  const [isDragging, setIsDragging] = useState(false);
  const [hoverOnPicker, setHoverOnPicker] = useState(false);

  const getPickerPositionFromColour = (colour, center, radius, lineWidth) => {
    const hsv = new Color(colour.red, colour.green, colour.blue).toHsv();
    const distance = hsv.s;
    const angle = (linear(hsv.h, 0, 360, 360, 0) - 90) / 180 * Math.PI;
    const maxDistance = radius - lineWidth;
    const normalisedPoints = calcPoints(distance, angle);
    const newPickerCenter = {
      x: center.x + normalisedPoints.x * maxDistance,
      y: center.y + normalisedPoints.y * maxDistance
    };
    return newPickerCenter;
  };

  const pickerPosition = getPickerPositionFromColour(colour, {x: 50, y: 50}, 50, 5);

  const calculateFillBackground = () => {
    const totalRange = maxOffset - minOffset;
    const defaultPosition = ((defaultOffset - minOffset) / totalRange) * 100;
    const currentPosition = ((offset - minOffset) / totalRange) * 100;

    const sliderColour = COLOUR_825;
    const sliderFillColour = COLOUR_400;

    const pos1 = (offset >= defaultOffset) ? defaultPosition : currentPosition;
    const pos2 = (offset >= defaultOffset) ? currentPosition : defaultPosition;

    return `linear-gradient(to right, ${sliderColour} ${pos1}%, ${sliderFillColour} ${pos1}%, ${sliderFillColour} ${pos2}%, ${sliderColour} ${pos2}%)`;
  };

  const handleMouseMove = (mousePosition) => {
    const center = { x: 0.0, y: 0.0 };
    if (isDragging) {
      const saturation = calcDistance(mousePosition, center);
      const angle = calcAngle(mousePosition, center);
      const hue = linear(((angle * 180) / Math.PI + 90) % 360, 0, 360, 360, 0);
      onColourChange(`${name}`, Color.fromHsv(hue, saturation, 0.5));
    }
  };

  return (
    <div 
    ref={ref} 
      style={{
        width: '100%',
        paddingTop: 20,
        paddingBottom: 10,
        gap: 20, display: 'flex', flexDirection: 'column', 
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ fontSize: '16px', fontWeight: 500, color: COLOUR_10 }}>
        {capitalize(name)}
      </div>
      <div style={{position: 'relative'}}>
        <HueRing
          pickerPosition={pickerPosition}
          hoverOnPicker={hoverOnPicker}
          onMouseEnterOnPicker={() => setHoverOnPicker(true)}
          onMouseLeaveOnPicker={() => setHoverOnPicker(false)}
          onMouseMove={handleMouseMove}
          onMouseDownOnPicker={() => setIsDragging(true)}
          onMouseUp={() => setIsDragging(false)}
          onMouseLeave={() => setIsDragging(false)}
          onTouchStartOnPicker={() => setIsDragging(true)}
          onTouchEnd={() => setIsDragging(false)}
          onTouchMove={handleMouseMove}
          isDisabled={isDisabled}
        />
        <div style={{top: 0, left: 0, padding: '10px 20px', position: 'absolute', display: 'flex', justifyContent: 'end', width: '100%'}}>
          <button
            style={{
              outline: 'none',
              border: 'none',
              backgroundColor: 'transparent',
              color: COLOUR_700,
              cursor: 'pointer',
              fontSize: '16px',
              zIndex: 3
            }}
            onClick={() => {
              const defaults = DEFAULT_COLOUR_PARAMETERS;
              onParameterChange(`${name}`, new Color(defaults.colour.red, defaults.colour.green, defaults.colour.blue), defaults.offset);
              updateHistory();
            }}
            disabled={isDisabled}
          >
            <FontAwesomeIcon icon={faRotateRight} style={{ fontSize: '100%' }}/>
          </button>
        </div>
      </div>

      <input
        id={`${name}-slider`}
        type="range"
        min={minOffset}
        max={maxOffset}
        step="0.01"
        value={offset}
        onChange={(e) => onOffsetChange(`${name}`, Number(e.target.value))}
        onMouseDown={updateHistory}
        style={{
          height: "5px",
          borderRadius: "5px",
          appearance: "none",
          WebkitAppearance: "none",
          outline: "none",
          width: "80%",
          cursor: "pointer",
          // marginTop: 100
          background: calculateFillBackground(),
        }}
        disabled={isDisabled}
      />
      <style>
        {`
          /* Webkit browsers (Chrome, Safari, Edge) */
          #${name}-slider::-webkit-slider-thumb {
            appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          /* Firefox */
          #${name}-slider::-moz-range-thumb {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          /* For IE (if necessary) */
          #${name}-slider::-ms-thumb {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          #${name}-slider::-webkit-slider-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }

          #${name}-slider::-moz-range-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }

          #${name}-slider::-ms-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }
        `}
      </style>
    </div>
  );
};
