/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { COLOUR_10 } from "../utils";

const UserColourMatchButton = ({ isDisabled, isMatchingColours, thumbnailSrc, onChange, isActive, onClickWhenLoaded, onDelete, onLoaded, width=120, widthOffset=20, height=220, heightOffsetPct="110%" }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: (isHovered || isActive) && isImageLoaded && (!isDisabled || isMatchingColours) ? width + widthOffset : width,
        height: height, // Fixed height for consistency
        transition: "all 0.5s ease",
        border: isImageLoaded ? "none" : "1px solid rgba(255,255,255,0.5)",
        overflow: "visible",
        cursor: isDisabled || isMatchingColours ? undefined : "pointer",
        position: "relative",
        boxShadow: isActive && isImageLoaded ? '0 0 30px rgb(0, 0, 255, 0.5)' : undefined,
        flexShrink: 0,
        opacity: (isDisabled && !isActive) ? 0.1 : undefined,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      { 
        isImageLoaded
        &&
        <button style={{    
          position: 'absolute',
          background: 'rgb(0,0,0)',
          color: COLOUR_10,
          top: (isHovered || isActive) && isImageLoaded && (!isDisabled || isMatchingColours) ? heightOffsetPct == '100%' ? '-10px' : '-20px' : '-10px',
          left: '-10px',
          width: '20px',
          height: '20px',
          borderRadius: "50%",
          border: "none",
          fontSize: "8px",
          fontWeight: "bold",
          justifyContent: "center",
          cursor: isDisabled || isMatchingColours ? undefined : "pointer",
          zIndex: 3,
          transition: 'all 0.5s ease'
          }}
          onClick={() => {
            onDelete();
            setIsImageLoaded(false);
          }}
          disabled={isDisabled || isMatchingColours}
        >
          <FontAwesomeIcon icon={faXmark} style={{ fontSize: '10px', color: COLOUR_10 }}/>
        </button>
      }
      {/* Thumbnail */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={thumbnailSrc}
          alt={"Image from user to color match"}
          style={{
            width: "100%",
            height: (isHovered || isActive) && (!isDisabled || isMatchingColours) && isImageLoaded ? heightOffsetPct : "100%",
            transition: 'all 0.5s ease',
            objectFit: "cover",
            objectPosition: "50% 50%",
            display: isImageLoaded ? "block" : "none", // Show only when loaded
            imageRendering: 'auto',
            pointerEvents: (isDisabled || isMatchingColours) ? "none" : undefined
          }}
          onLoad={() => {
            setIsImageLoaded(true);
            onLoaded();
          }}
          onClick={() => {
            if (!isImageLoaded) return;
            onClickWhenLoaded();
          }}
          draggable={false}
        />
        {!isImageLoaded && <span style={{ color: "#aaa" }}>+</span>} {/* Placeholder text */}
      </div>

      {/* Hidden file input */}
      {
        !isImageLoaded
        &&
        <input
          type="file"
          accept="image/*"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0, // Make the input file invisible
            cursor: isDisabled || isMatchingColours ? undefined : "pointer",
          }}
          onChange={onChange}
          disabled={isDisabled || isMatchingColours}
        />
      }
    </div>
  );
};

export default UserColourMatchButton;
