/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { COLOUR_10, COLOUR_1000, COLOUR_600, COLOUR_800, COLOUR_BLUE } from './utils';
import Spinner from './Spinner';
import { AppContext } from '../AppContext';
import CanvasDownloader from './CanvasDownloader';

export const DownloadModal = ({ isMobile, isDisabled }) => {
  const [hoverOnDownload, setHoverOnDownload] = useState(false);
  const [hoverOnPurchase, setHoverOnPurchase] = useState(false);
  const [download, setDownload] = useState(false);
  const { isDownloading, setIsShowDownload } = useContext(AppContext);

  const downloadButton = isMobile ? 
  (
    <>
    <p style={{color: COLOUR_10}}>Share this for <strong>free</strong> with friends on <strong>social media</strong> or save it to your Photos!</p>
    <button
      id="share-image-button"
      style={{
        backgroundColor: COLOUR_BLUE,
        border: 'none',
        color: COLOUR_1000,
        outline: 'none',
        padding: hoverOnDownload ? '15px 30px' : '15px 20px',
        cursor: isDisabled ? undefined : 'pointer',
        borderRadius: '5rem',
        fontSize: '14px',
        transition: 'all 0.5s ease',
        fontWeight: 500,
        zIndex: 3,
        marginRight: 10,
        opacity: isDisabled ? 0.4 : 1,
      }}
      onMouseEnter={() => setHoverOnDownload(true)}
      onMouseLeave={() => setHoverOnDownload(false)}
      disabled={isDisabled}
      onClick={() => setDownload(true)}
    >
      <div style={{display: 'flex', gap: 10, justifyContent: 'center', alignItems: 'center'}}>
        <>Download</>
        {isDownloading && <Spinner size={14} color={COLOUR_BLUE} backgroundColor={COLOUR_10} thickness={1}/>}
      </div>
    </button>
    </>
  )
    :
  (
    <>
      <p style={{color: COLOUR_10}}>Download the file for <strong>free</strong> to your <strong>device</strong> and use it anywhere.</p>
      <button
        id="download-image-button"
        style={{
          backgroundColor: COLOUR_BLUE,
          border: 'none',
          color: COLOUR_1000,
          outline: 'none',
          padding: hoverOnDownload ? '15px 30px' : '15px 20px',
          cursor: isDisabled ? undefined : 'pointer',
          borderRadius: '5rem',
          fontSize: '14px',
          transition: 'all 0.5s ease',
          fontWeight: 500,
          zIndex: 3,
          marginRight: 10,
          opacity: isDisabled ? 0.4 : 1,
        }}
        onMouseEnter={() => setHoverOnDownload(true)}
        onMouseLeave={() => setHoverOnDownload(false)}
        disabled={isDisabled}
        onClick={() => setDownload(true)}
      >
        <div style={{display: 'flex', gap: 10, justifyContent: 'center', alignItems: 'center'}}>
          <>Download</>
          {isDownloading && <Spinner size={14} color={COLOUR_BLUE} backgroundColor={COLOUR_10} thickness={1}/>}
        </div>
      </button>    
    </>
  );


  return (
    <div
      style={{
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100
      }}
    >

      <div
        style={{
          display: 'flex',
          backgroundColor: COLOUR_800,
          borderRadius: '20px',
          width: isMobile ? '90%' : '50%',
          padding: isMobile ? '40% 0px' : '5% 0px',
          zIndex: 100,
          position: 'relative'
        }}
      >
        <button
          style={{
            position: 'absolute',
            width: '30px',
            height: '30px',
            top: '-10px',
            left: '-10px',
            borderRadius: '15px',
            border: `1px solid ${COLOUR_600}`,
            outline: 'none',
            backgroundColor: 'rgb(0,0,0)',
            color: COLOUR_10,
            fontSize: '13px',
            cursor: isDisabled ? undefined : 'pointer'
          }}
          onClick={() => setIsShowDownload(false)}
        >
          <div><FontAwesomeIcon icon={faXmark}/></div>
        </button>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            gap: 50
          }}
        >
          {/* Download */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              padding: '0px 20px',
              gap: 20
            }}
          >
            {downloadButton}
          </div>

          {/* Buy me a coffee */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              padding: '0px 20px',
              gap: 20
            }}
          >
            <p style={{color: COLOUR_10}}>Your support helps! Pay what feels right and fuel future updates. 🙌.</p>
            <button
              id="buy-image-button"
              style={{
                backgroundColor: 'rgb(243, 243, 47)',
                color: COLOUR_1000,
                outline: hoverOnPurchase ? '2px solid black' : '1px solid black',
                border: 'none',
                padding: hoverOnPurchase ? '15px 30px' : '15px 20px',
                cursor: isDisabled ? undefined : 'pointer',
                borderRadius: '5rem',
                fontSize: '14px',
                transition: 'all 0.5s ease',
                fontWeight: 500,
                zIndex: 3,
                marginRight: 10,
                opacity: isDisabled ? 0.4 : 1
              }}
              onMouseEnter={() => setHoverOnPurchase(true)}
              onMouseLeave={() => setHoverOnPurchase(false)}
              disabled={isDisabled}
              onClick={() => {
                window.open('https://www.buymeacoffee.com/rekareka', '_blank');
              }}
            >
              Buy me a coffee
            </button>
          </div>

        </div>
      </div>

    <CanvasDownloader download={download} toShare={isMobile} onFinished={() => setDownload(false)}/>
    </div>
  );
};

export default DownloadModal;
