/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebase";
import { COLOUR_10, COLOUR_700, COLOUR_850, COLOUR_900 } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import SampleColourMatchButton from "./SampleColourMatchButton";
import UserColourMatchButton from "./UserColourMatchButton";
import { AutoLogo } from "../AutoLogo";

export const ColourMatching = ({ isMatchingColours, isDisabled, targetImageThumbnail, params, onTargetChange, onDeleteUserImage, onUserImageLoading, onUserImageLoaded }) => {
  const [filters, setFilters] = useState([]);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const scrollContainerRef = useRef(null); // Ref for the scrollable container

  // Fetch filters
  useEffect(() => {
    const fetchImages = async () => {
      const folderRef = ref(storage, "references");
      try {
        const result = await listAll(folderRef);
        let filters = await Promise.all(
          result.items.map(async (item) => {
            const name = item.name.split(".")[0];
            const thumbnail = await getDownloadURL(item);
            const filename = item.name;
            return { name, thumbnail, filename };
          })
        );
        filters = filters.filter((item) => item.filename.endsWith("400p.jpg"));
        setFilters(filters);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchImages();
  }, []);

  useEffect(() => {
    updateScrollState();
  }, [filters]);

  // Check scroll position
  const updateScrollState = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0); // Can scroll left if scrollLeft > 0
      setCanScrollRight(container.scrollLeft < container.scrollWidth - container.clientWidth); // Can scroll right if content overflows
    }
  };

  // Attach scroll listener and initial check
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      updateScrollState(); // Initial check for scrollability
      container.addEventListener("scroll", updateScrollState);
      return () => container.removeEventListener("scroll", updateScrollState); // Cleanup
    }
  }, []); // Empty dependency array to run only once on mount

  // Scroll handler
  const handleScroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = 120 + 10; // Adjust scroll amount
      container.scrollBy({ left: direction === "left" ? -scrollAmount : scrollAmount, behavior: "smooth" });
    }
  };

  const handleFilterClick = (filter) => {
    onTargetChange(filter);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Stack title and filters container
        width: "100%",
        backgroundColor: COLOUR_900,
        position: "relative", // For arrow positioning
      }}
    >
      {/* Title */}
      <div
        style={{
          color: COLOUR_10,
          padding: "15px",
          borderBottom: `1px solid ${COLOUR_700}`,
          fontSize: "16px",
          fontWeight: 700,
          backgroundColor: COLOUR_850,
          display: "flex",
          cursor: 'default',
          textWrap: 'nowrap',
          gap: 10
        }}
      >
        <div>Color Matching</div>
        <AutoLogo/>
      </div>

      {/* Arrow: Left */}
      {canScrollLeft && (
        <div
          onClick={() => handleScroll("left")}
          style={{
            position: "absolute",
            top: "50%",
            left: "5px",
            cursor: "pointer",
            zIndex: 10,
            background: COLOUR_900,
            opacity: 0.5,
            borderRadius: "5px",
            padding: '10px 5px'
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} style={{ color: COLOUR_10 }} />
        </div>
      )}

      {/* Scrollable Filters */}
      <div
        ref={scrollContainerRef}
        style={{
          display: "flex",
          gap: "10px",
          overflowX: "scroll",
          whiteSpace: "nowrap",
          padding: "30px 20px",
          width: "100%",
        }}
      >
        <UserColourMatchButton
          thumbnailSrc={targetImageThumbnail}
          onChange={(e) => {
            const file = e.target.files[0];
            if (!file) return;
            onUserImageLoading();
            onTargetChange({name: 'user', thumbnail: URL.createObjectURL(file)});
          }}
          onLoaded={onUserImageLoaded}
          onClickWhenLoaded={() => onTargetChange({name: 'user', thumbnail: targetImageThumbnail})}
          isActive={params.filter?.name === 'user' && params.filter?.thumbnail === targetImageThumbnail}
          onDelete={onDeleteUserImage}
          isDisabled={isDisabled}
          isMatchingColours={isMatchingColours}
         />
        {
          filters.map((filter) => (
            <SampleColourMatchButton
              key={filter.name}
              filterName={filter.name.slice(0, 5)}
              thumbnailSrc={filter.thumbnail}
              onClick={() => handleFilterClick(filter)}
              isActive={params.filter?.name === filter.name}
              isDisabled={isDisabled}
              isMatchingColours={isMatchingColours}
            />
          ))
        }
      </div>

      {/* Arrow: Right */}
      {canScrollRight && (
        <div
          onClick={() => handleScroll("right")}
          style={{
            position: "absolute",
            top: "50%",
            right: "5px",
            cursor: "pointer",
            zIndex: 10,
            background: COLOUR_900,
            opacity: 0.5,
            borderRadius: "5px",
            padding: '10px 5px'
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} style={{ color: COLOUR_10 }} />
        </div>
      )}
    </div>
  );
};

export default ColourMatching;
