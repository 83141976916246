import React, { useState } from 'react';
import { AppContext } from './AppContext';
import { DEFAULT_COLOUR_PARAMETERS, DEFAULT_CONTRAST, DEFAULT_EXPOSURE, DEFAULT_SATURATION } from './components/matchUtils';

// Provider component
export const AppProvider = ({ children }) => {
  const [sourceImage, setSourceImage] = useState(null);
  const [targetImage, setTargetImage] = useState(null);
  const [targetImageThumbnail, setTargetImageThumbnail] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [imgDataForMatching, setImgDataForMatching] = useState(null);
  const [targetImgData, setTargetImgData] = useState(null);
  const [isMatchingColours, setIsMatchingColours] = useState(false);
  const [isMatchingColourUserImageLoading, setIsMatchingColourUserImageLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isShowDownload, setIsShowDownload] = useState(false);
  const [error, setError] = useState(false);
  const defaultParams = {
    saturation: DEFAULT_SATURATION,
    contrast: DEFAULT_CONTRAST,
    exposure: DEFAULT_EXPOSURE,
    shadowParameters: DEFAULT_COLOUR_PARAMETERS,
    midtoneParameters: DEFAULT_COLOUR_PARAMETERS,
    highlightParameters: DEFAULT_COLOUR_PARAMETERS,
    filter: null
  };
  const [params, setParams] = useState(defaultParams);
  const [history, setHistory] = useState({
    past: [],
    future: []
  });

  return (
    <AppContext.Provider
      value={{
        sourceImage,
        setSourceImage,
        targetImage,
        setTargetImage,
        targetImageThumbnail,
        setTargetImageThumbnail,
        imgData,
        setImgData,
        imgDataForMatching,
        setImgDataForMatching,
        targetImgData,
        setTargetImgData,
        isMatchingColours,
        setIsMatchingColours,
        isMatchingColourUserImageLoading,
        setIsMatchingColourUserImageLoading,
        isDownloading,
        setIsDownloading,
        params,
        setParams,
        history,
        setHistory,
        error,
        setError,
        isShowDownload,
        setIsShowDownload
      }}
    >
        {children}
    </AppContext.Provider>
  );
};