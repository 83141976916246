import React from 'react';
import { Helmet } from 'react-helmet';
import './LandingPage.css'; // Import the external CSS file


export const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>Reka-Reka | Automated Color Grading & Photo Editing</title>
        <meta name="description" content="Transform your photos with Reka-Reka's smart automated color grading. Steal colors, apply stunning edits, and elevate your photos. Try it for free!" />
        <meta name="keywords" content="color grading, automated photo editing, image editor, photo color tools, smart editing tools, image filters, social media sharing, photo enhancement, image retouching" />
        <link rel="canonical" href="https://reka-reka.com/landing" />
        <meta property="og:title" content="Reka-Reka | Automated Color Grading & Photo Editing" />
        <meta property="og:description" content="Easily enhance your photos with Reka-Reka's AI-powered color grading. Steal colors and create stunning edits. Free and effortless!" />
        <meta property="og:image" content="https://reka-reka.com/logo.png" />
        <meta property="og:url" content="https://reka-reka.com/landing" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Reka-Reka | Automated Color Grading & Photo Editing" />
        <meta name="twitter:description" content="Transform your photos with smart color grading and editing tools. Try Reka-Reka for free today!" />
        <meta name="twitter:image" content="https://reka-reka.com/logo.png" />
        <meta name="robots" content="index, follow" />
        <link rel="icon" href="https://reka-reka.com/favicon.ico" sizes="any" />
        <link rel="apple-touch-icon" href="https://reka-reka.com/apple-touch-icon.png" />
      </Helmet>


      <main className="landing">
        <header>
          <h1 className="title">Transform Your Images with Smart-Powered Color Grading</h1>
          <p className="paragraph">
          Enhance your photos with one click! Reka-Reka applies professional-grade color grading and edits in seconds, helping you achieve stunning visuals effortlessly. With our advanced smart technology, you no longer need complex editing software or hours of manual adjustments. Simply upload, apply, and let Reka-Reka do the magic for you. Whether you're a content creator, a photographer, or just someone who loves beautiful photos, our tool makes professional photo editing accessible to everyone. Elevate your visuals, match colors instantly, and create breathtaking images with ease!
          </p>
        </header>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 50
        }}>
          <a href="/" className="cta-button">Start Editing Now</a>
        </div>

        <section className="section">
          <h2 className="subtitle">Why Choose Reka-Reka?</h2>
          <ul className="list">
            <li><strong>Steal Colors:</strong> Instantly match tones and palettes from any reference image.</li>
            <li><strong>Automated Color Grading:</strong> Achieve professional results with automated adjustments.</li>
            <li><strong>Simple and Fast:</strong> Edit images effortlessly, no design skills required.</li>
            <li><strong>Color References:</strong> Use stunning presets or use your own to elevate your images.</li>
            <li><strong>Social Media Ready:</strong> Edit, enhance, and share directly to Instagram, Facebook, and more!</li>
          </ul>
        </section>

        <section className="step-section">
          <h2 className="step-title">How It Works</h2>
          <ol className="list">
            <li><strong>Upload:</strong> Drag and drop your image or select one from your device.</li>
            <li><strong>Edit:</strong> Adjust exposure, saturation, and color grading, or steal colors from a reference photo.</li>
            <li><strong>Download & Share:</strong> Save your masterpiece in the same resolution or share directly to social media for FREE.</li>
          </ol>
          <a href="/how-it-works" className="link">Learn more about how it works</a>
        </section>

        <section className="section">
          <h2 className="subtitle">Start Editing Today</h2>
          <p className="paragraph">
            Experience the easiest way to edit and enhance your photos. Join thousands of creators using Reka-Reka to make their images shine.
          </p>
          <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 50
        }}>
          <a href="/" className="cta-button">Try it for Free!</a>
        </div>
        </section>

      
      </main>
    </>
  );
};

export default LandingPage;
