import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { COLOUR_10, COLOUR_900, COLOUR_950, COLOUR_BLUE } from "./utils";

export const Help = ({isMobile}) => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  const faqs = [
    {
      question: "Why can't I upload an image?",
      answer: "Make sure your file is in JPG, PNG, or WebP format.",
    },
    {
      question: "How do I match colors with a reference image?",
      answer: "In the Color Matching section on the top right corner of the editor, you can either choose a sample reference or upload your own. The app will automatically adjust the colors and other settings for you once you upload a reference image or press a sample image.",
    },
    {
      question: "What image formats can I download?",
      answer: "The same image format as your uploaded image. Either JPG, PNG or WebP.",
    },
  ];

  const styles = {
    container: {
      fontFamily: "Instrument Sans",
      padding: isMobile ? "100px 15%" : "100px 20%",
      width: "100%",
      margin: "0 auto",
      backgroundColor: COLOUR_950,
    },
    header: {
      textAlign: "center",
      marginBottom: "20px",
    },
    title: {
      fontSize: isMobile ? "2.5rem" : "4rem",
      marginBottom: "100px",
      color: COLOUR_10
    },
    subtitle: {
      fontSize: isMobile ? "1.5rem" : "2rem",
      color: COLOUR_10
    },
    faqSection: {
      marginTop: "100px",
    },
    faqTitle: {
      fontSize: isMobile ? "1.2rem" : "1.5rem",
      marginBottom: "50px",
      color: COLOUR_10
    },
    faqList: {
      listStyleType: "none",
      padding: 0,
    },
    faqItem: {
      marginBottom: "15px",
    },
    footer: {
      textAlign: "center",
      marginTop: "30px",
    },
    link: {
      color: "#007bff",
      textDecoration: "none",
      fontWeight: "bold",
    },
    paragraph: {
      fontSize: isMobile ? '1.0rem' : '1.1rem',
      lineHeight: 1.6,
      color: COLOUR_10,
      marginBottom: '5px'
    }
  };

  return (
    <>
      <Helmet>
        <title>Help Center | Get Support for Reka-Reka | Reka-Reka</title>
        <meta 
          name="description" 
          content="Find solutions and answers to your questions in the Reka-Reka Help Center. Learn about image editing, troubleshooting, and tips for using our automated tools." 
        />
        <meta 
          name="keywords" 
          content="help center, support, FAQs, Reka-Reka troubleshooting, image editing help, photo editing support" 
        />
        <link rel="canonical" href="https://reka-reka.com/help" />
        <meta property="og:title" content="Help Center | Get Support for Reka-Reka | Reka-Reka" />
        <meta property="og:description" content="Need help using Reka-Reka? Visit our Help Center for answers to FAQs, troubleshooting tips, and guidance on using our automated photo editing tools." />
        <meta property="og:image" content="https://reka-reka.com/logo.png" />
        <meta property="og:url" content="https://reka-reka.com/help" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Help Center | Get Support for Reka-Reka | Reka-Reka" />
        <meta name="twitter:description" content="Need assistance with Reka-Reka? Our Help Center has FAQs, troubleshooting advice, and step-by-step guides to make your experience seamless." />
        <meta name="twitter:image" content="https://reka-reka.com/logo.png" />
        <meta name="robots" content="index, follow" />
        <link rel="icon" href="https://reka-reka.com/favicon.ico" sizes="any" />
        <link rel="apple-touch-icon" href="https://reka-reka.com/logo.png" />
      </Helmet>

      {/* Help Page Content */}
      <main style={styles.container}>
        {/* Header Section */}
        <header style={styles.header}>
          <h1 style={styles.title}>Help Center</h1>
          <p style={styles.paragraph}>
            Need assistance? Find answers to frequently asked questions.
          </p>
        </header>

        {/* FAQ Section */}
        <section style={styles.faqSection}>
          <h2 style={styles.faqTitle}>Frequently Asked Questions (FAQs)</h2>
          <ul style={styles.faqList}>
            {faqs.map((faq, index) => (
              <li key={index} style={styles.faqItem}>
                <div
                  style={{
                    backgroundColor: COLOUR_900,
                    border: "none",
                    textAlign: "left",
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: COLOUR_10,
                    cursor: "pointer",
                    padding: '50px 40px',
                    borderRadius: activeQuestion === index ? '20px' : '10px',
                    transition: 'all 0.5s ease'
                  }}
                  onClick={() => toggleQuestion(index)}
                >
                  <div style={{
                    marginBottom: activeQuestion === index ? '20px' : '0px',
                    transition: 'all 0.5s ease'
                  }}>
                  {faq.question}
                  </div>
                  <div style={{
                    transition: 'all 0.5s ease',
                    opacity: activeQuestion === index ? 1.0 : 0.0,
                    color: COLOUR_BLUE,
                    lineHeight: '24px',
                    fontWeight: 500
                  }}>
                    {activeQuestion === index && faq.answer}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </section>

        {/* Footer Section */}
        {/* <footer style={styles.footer}>
          <p>
            Still have questions? <a href="/contact" style={styles.link}>Contact us</a>.
          </p>
        </footer> */}
      </main>
    </>
  );
};

export default Help;
