/* eslint-disable react/prop-types */
import React from 'react';
import { COLOUR_10, COLOUR_700, COLOUR_850, COLOUR_900 } from '../utils';
import ContrastSlider from './ContrastSlider';
import ExposureSlider from './ExposureSlider';
import SaturationSlider from './SaturationSlider';

const LightingSaturation = ({isDisabled, updateHistory, params, handleContrastChange, handleExposureChange, handleSaturationChange}) => {
  return (
            <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundColor: COLOUR_900,
              paddingBottom: 30,
              gap: 20
            }}
            >
            <div 
              style={{
                color: COLOUR_10,
                padding: '15px',
                borderBottom: `1px solid ${COLOUR_700}`,
                fontSize: '16px',
                fontWeight: 700,
                backgroundColor: COLOUR_850,
                cursor: 'default',
                userSelect: 'none',
                WebkitUserSelect: 'none',
                msUserSelect: 'none'
              }}
            >
              Lighting & Saturation
            </div>
            <ContrastSlider
              contrast={params.contrast}
              onChange={handleContrastChange}
              updateHistory={updateHistory}
              isDisabled={isDisabled}
            />
            <ExposureSlider
              exposure={params.exposure}
              onChange={handleExposureChange}
              updateHistory={updateHistory}
              isDisabled={isDisabled}
            />
            <SaturationSlider
              saturation={params.saturation}
              onChange={handleSaturationChange}
              updateHistory={updateHistory}
              isDisabled={isDisabled}
            />
            </div>
  );
};

export default LightingSaturation;
