/* eslint-disable react/prop-types */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { COLOUR_10, COLOUR_400, COLOUR_700, COLOUR_800, COLOUR_825, COLOUR_950 } from '../utils';
import { DEFAULT_SATURATION } from '../matchUtils';

export const SaturationSlider = ({ isDisabled, saturation, onChange, updateHistory }) => {
  const minSaturation = 0.0;
  const maxSaturation = 2.0;
  const defaultSaturation = DEFAULT_SATURATION;
  const thumbColour = COLOUR_950;
  const thumbBorder = `2px solid ${COLOUR_800}`;

  const calculateFillBackground = () => {
    const totalRange = maxSaturation - minSaturation;
    const defaultPosition = ((defaultSaturation - minSaturation) / totalRange) * 100;
    const currentPosition = ((saturation - minSaturation) / totalRange) * 100;

    const sliderColour = COLOUR_825;
    const sliderFillColour = COLOUR_400;

    const pos1 = (saturation >= defaultSaturation) ? defaultPosition : currentPosition;
    const pos2 = (saturation >= defaultSaturation) ? currentPosition : defaultPosition;

    return `linear-gradient(to right, ${sliderColour} ${pos1}%, ${sliderFillColour} ${pos1}%, ${sliderFillColour} ${pos2}%, ${sliderColour} ${pos2}%)`;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%", // Ensure the container spans the full width
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between', // Push items to opposite ends
          width: '100%', // Optional: Ensure it fills the container width
          marginBottom: '10px',
          cursor: 'default'
        }}
      >
        <div style={{ marginLeft: '5%', fontSize: '16px', fontWeight: 500, color: COLOUR_10 }}>
          Saturation
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '5%',
          }}
        >
          <button
            style={{
              outline: 'none',
              border: 'none',
              backgroundColor: 'transparent',
              marginRight: 3,
              color: COLOUR_700,
              cursor: 'pointer',
            }}
            onClick={() => {
              onChange(DEFAULT_SATURATION);
              updateHistory();
            }}
            disabled={isDisabled}
          >
            <FontAwesomeIcon icon={faRotateRight} style={{ fontSize: '100%' }} />
          </button>
          <div
            style={{
              fontSize: '16px',
              fontWeight: 500,
              color: COLOUR_10,
              minWidth: `${16 * 0.8 * 4}px`, // Reserve space for 3-digit percentages
              textAlign: 'right', // Align the text to the right
            }}
          >
            {Math.round(saturation * 100)}%
          </div>
        </div>
      </div>
      <input
        id="saturation-slider"
        type="range"
        min={minSaturation}
        max={maxSaturation}
        step="0.05"
        value={saturation}
        onChange={(e) => onChange(Number(e.target.value))}
        onMouseDown={updateHistory}
        style={{
          height: "5px",
          borderRadius: "5px",
          appearance: "none",
          WebkitAppearance: "none",
          outline: "none",
          width: "90%",
          cursor: "pointer",
          background: calculateFillBackground(),
        }}
        disabled={isDisabled}
      />
      <style>
        {`
          /* Webkit browsers (Chrome, Safari, Edge) */
          #saturation-slider::-webkit-slider-thumb {
            appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          /* Firefox */
          #saturation-slider::-moz-range-thumb {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          /* For IE (if necessary) */
          #saturation-slider::-ms-thumb {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: ${thumbColour}; /* Thumb color */
            border: ${thumbBorder}; /* Optional border */
            cursor: pointer;
            transition: transform 0.2s ease, left 0.2s ease;
          }

          #saturation-slider::-webkit-slider-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }

          #saturation-slider::-moz-range-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }

          #saturation-slider::-ms-thumb:hover {
            transform: scale(1.2); /* Slightly enlarge the thumb on hover */
          }
        `}
      </style>
    </div>
  );
};

export default SaturationSlider;
